import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import IMG from "../assets/image/dummy.png";
import NoData from "../components/NoData";
import CollectionCard from "../components/CollectionCard";
import Loader from "../components/Loader";
import { useGetPrivacyQuery } from "../services/apis";
import parse from "html-react-parser";

const PrivacyPage = () => {
	const { data, refetch, isFetching } = useGetPrivacyQuery();

	useEffect(() => {
		refetch();
	}, []);

	return (
		<>
			<Navbar />
			{isFetching ? (
				<Loader />
			) : (
				<>
					<div className="bg-white mt-28 mb-0 ">
						<section
							className="site-container cmsPage py-20"
							style={{ marginBottom: "0px" }}
						>
							{parse(`${data?.data?.privacy_policy || "coming soon"}`)}
						</section>
					</div>
				</>
			)}
			<Footer />
		</>
	);
};

export default PrivacyPage;
