import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Loader from "../components/Loader";
import { useGetLegalQuery } from "../services/apis";
import parse from "html-react-parser";

const PrivacyPage = () => {
	const { data, refetch, isFetching } = useGetLegalQuery();
	useEffect(() => {
		refetch();
	}, []);

	return (
		<>
			<Navbar />
			{isFetching ? (
				<Loader />
			) : (
				<>
					<div className="bg-white mt-28 mb-0 ">
						<section
							className="site-container cmsPage py-20"
							style={{ marginBottom: "0px" }}
						>
							{parse(`${data?.data?.help_and_guidance}`)}
						</section>
					</div>
				</>
			)}
			<Footer />
		</>
	);
};

export default PrivacyPage;
