import React from "react";
import { Link } from "react-router-dom";

const HomeHeading = ({ title, description = "", btn = false }) => {
  return (
    <div className="site-container">
      <div className={`${btn && "flex items-center justify-between"}`}>
        <h2
          className="text-5xl mt-20 mb-0 text-first bannerText text-left"
          style={{
            fontWeight: "bold",
            borderLeft: "5px solid #e75480",
            paddingLeft: ".5em",
          }}
        >
          {title}
        </h2>
        {description && (
          <p className="text-gray-600 mainText text-left leading-10 mt-12">
            {description}
          </p>
        )}
        {btn && (
          <Link to="/why-us" className="text-2xl border-second px-5 py-2 mt-20">
            Explore More
          </Link>
        )}
      </div>
    </div>
  );
};

export default HomeHeading;
