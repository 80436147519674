import React from "react";

import { ColorRing } from "react-loader-spinner";

const Loader = ({ button }) => {
	return (
		<div
			style={{
				width: "100%",
				display: "grid",
				marginTop: "50px",
				placeItems: "center",
				height: button ? "0px" : "60vh",
			}}
		>
			<ColorRing
				visible={true}
				height={button ? "20" : "80"}
				width={button ? "20" : "80"}
				ariaLabel="blocks-loading"
				wrapperStyle={{}}
				wrapperClass="blocks-wrapper"
				colors={["#f8b26a", "#f8b26a", "#f8b26a", "#f8b26a", "#f8b26a"]}
			/>
		</div>
	);
};

export default Loader;
