import React, { useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import IMG from "../assets/image/dummy.png";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetAddressStatusQuery, useGetProfileQuery, useVerifyRequestMutation } from "../services/apis";
import { useEffect } from "react";
import MintedNfts from "./MintedNfts";
import Modal from "../components/Modal/Modal";
import { PopUp } from "../utils/alert";
import DotLoader from "../components/DotLoader";

const UserProfile = () => {
	const navigate = useNavigate();
	const profileData = useSelector((state) => state.constants.profileData);
	const [copy, setCopy] = useState(false);
	const [verifyAlert, setVerifyAlert] = useState(false);

	const { data, refetch, isFetching } = useGetProfileQuery(
		profileData?.data?._id
	);

	const { data: addressData, refetch: addressRefetch } = useGetAddressStatusQuery(
		profileData?.data?.wallet_address
	);

	const [verifyRequest, { data: requestData, isLoading, isError }] = useVerifyRequestMutation()

	useEffect(() => {
		refetch();
		addressRefetch()
	}, []);


	useEffect(() => {

		if (requestData?.success) {
			PopUp("Request sent successfully", "", "success");
			setVerifyAlert(false)
			return
		}

		if (requestData?.success === false) {
			PopUp("Something went wrong", requestData?.message, "error");
			setVerifyAlert(false)

			return;
		}

	}, [requestData])


	return (
		<>
			<Navbar />
			{isFetching ? (
				<Loader />
			) : (
				<>
					<div className="bg-white mt-28 mb-20">
						<section className="container">
							<div className="mt-12 flex items-center">
								<img
									src={
										data?.data?.display_picture
											? `${data?.data?.display_picture}`
											: IMG
									}
									alt=""
									className="w-80 h-80 "
								/>
								<div className="ml-10 text-left">
									{profileData?.data?.is_verified === 1 && addressData?.data[0]?.isDesignated && (
										<span className="text-white bg-second px-3 py-1">
											<i class="fa-solid fa-check mr-3"></i>Account Verified
										</span>
									)}
									<h3 className="text-5xl font-bold bannerText mt-3">
										{data?.data?.name || "Unnamed"}
									</h3>
									<p className="text-2xl text-gray-400 mt-3">
										{profileData?.data?.wallet_address?.slice(0, 5) +
											"..." +
											profileData?.data?.wallet_address?.slice(-5)}{" "}
										<CopyToClipboard
											text={profileData?.data?.wallet_address}
											onCopy={() => setCopy(true)}
										>
											<span>
												{copy ? (
													<i class="fa-solid fa-circle-check ml-6 text-2xl text-green-600"></i>
												) : (
													<i class="fa-regular fa-copy ml-6 text-2xl text-purple-600"></i>
												)}
											</span>
										</CopyToClipboard>
									</p>
									<p className="text-xl text-gray-600 mt-2">
										Joined on{" "}
										{moment(profileData?.data?.createdAt).format("DD MMM YYYY")}
									</p>
									<div className="mt-8 flex items-center gap-6">
										<button
											className="profile-btn2"
											onClick={() => navigate("/add-nft")}
											disabled={profileData?.data?.is_verified === 0}
										>
											Create NFT
										</button>
										<button
											className="profile-btn"
											onClick={() =>
												navigate(`/edit-profile/${profileData?.data?._id}`)
											}
										>
											Edit Profile
										</button>
										{!addressData?.data[0]?.isDesignated && <button
											className="profile-btn3"
											onClick={() =>
												setVerifyAlert(true)
											}
										>
											{
												addressData?.data[0]?.status ? "Verification requested" : "Verify Account"
											}

										</button>}
									</div>
									{profileData?.data?.is_verified === 0 && (
										<div className="flex items-center justify-start w-full">
											<div
												className="mt-6 bg-red-300 px-4 py-1  flex items-center gap-20"
												style={{ width: "max-content" }}
											>
												<p className="text-lg text-red-800">
													Please verify you email in order to create and sell
													nfts on marketplace.
												</p>
												<button
													className="bg-red-700 px-3 py-1 rounded text-white text-lg text-bold"
													onClick={() =>
														navigate(`/edit-profile/${profileData?.data?._id}`)
													}
												>
													Verify
												</button>
											</div>
										</div>
									)}
								</div>
							</div>
						</section>

						{profileData?.data?.role === "user" ||
							(profileData?.data?.role === "creator" && <MintedNfts />)}

						{profileData?.data?.role === "admin" && (
							<>
								<MintedNfts />
							</>
						)}
					</div>
				</>
			)}

			<Modal show={verifyAlert} onClose={() => setVerifyAlert(false)} title="Verify your Address">
				<div>
					<h2 className="bannerText text-4xl font-bold"></h2>
					<p className="text-gray-900 text-2xl mt-3 text-center">It will help to mint your assests without showing on marketplace.</p>
					<div className="mt-16 flex items-center gap-6">
						<button
							className="profile-btn2 flex-1"
							onClick={() => setVerifyAlert(false)}
						>
							Cancel
						</button>
						<button
							className="profile-btn flex-1"
							onClick={() => verifyRequest({ address: profileData?.data?.wallet_address })}

						>
							{
								isLoading ? <DotLoader /> : "Confirm"
							}
						</button>
					</div>
				</div>

			</Modal>

			<Footer />
		</>
	);
};

export default UserProfile;
