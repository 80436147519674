import React from "react";
import HomeHeading from "./HomeHeading";
import Navbar from "./Navbar";
import Footer from "./Footer";
import img2 from "../assets/img4.JPG";

const CreateSell = ({ page = false }) => {
  const Grids = ({ title, description, value = "1" }) => {
    return (
      <div className="flex flex-col items-start ">
        <div className="w-12 h-12 flex items-center justify-center bg-second text-2xl font-bold text-white">
          {value}
        </div>
        {/* <h2 className="font-bold text-5xl mb-4 mt-6 text-black text-left bannerText">
          {title}
        </h2> */}
        <p className="text-gray-500 mainText mt-6 text-left leading-10">
          {description}
        </p>
      </div>
    );
  };
  return (
    <>
      {page && <Navbar />}
      <div className={`${page && "mt-40"} mb-40`} id="creates">
        <HomeHeading title="How It Works" description="" btn/>
        <div className="mb-28"></div>
        <div className="flex flex-col lg:flex-row gap-12 site-container">
          <div className="grid grid-cols-2 sm:grid-cols-2 gap-12  md:grid-cols-2 xl:grid-cols-3 text-center mx-auto ">
            <Grids
              title="Curated by experts"
              value="1"
              description="Artequitys NFT curates some of the most exclusive Art pieces and assets from galleries and collectors across the world."
            ></Grids>

            <Grids
              title="
					Valuation and Contract"
              value="2"
              description="All the artworks acquired to sell through fractionalise NFT Share sale will be auctioned throw our Auction House."
            ></Grids>

            <Grids
              title="	Photography"
              value="3"
              description="Once art work is acquired we will mint a NFT of the artwork."
            ></Grids>

            <Grids
              title="NFT Minted"
              value="4"
              description="Artwork and NFT will be auctioned through our auction house."
            ></Grids>

            <Grids
              title="NFT Auction"
              value="5"
              description="Highest bidder will get the physical artwork and well as the NFT of the artwork."
            ></Grids>

            <Grids
              title="Asset Acquired"
              value="6"
              description="We will have the lowest commissions in comparison to the traditional auction houses which have levied the buyer's & seller’s commission for several decades, particularly in fine art auctions, with percentages varying from 20–40% percent"
            ></Grids>

            
          </div>
          <img src={img2} alt="" className="w-full lg:w-1/4 " />
        </div>
      </div>
      {page && <Footer />}
    </>
  );
};

export default CreateSell;
