import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import IMG from "../assets/image/dummy.png";
import { useNavigate, useParams } from "react-router-dom";
import {
	useCollectionByIdQuery,
	useNftInCollectionQuery,
} from "../services/apis";
import NoData from "../components/NoData";
import CollectionCard from "../components/CollectionCard";
import Loader from "../components/Loader";

const ViewCollection = () => {
	const { id } = useParams();

	const {
		data: listCat,
		refetch: listLoad,
		isFetching,
	} = useCollectionByIdQuery(id);
	const {
		data: nftList,
		refetch: nftCollection,
		isFetching: isFetchingNft,
	} = useNftInCollectionQuery(id);

	useEffect(() => {
		listLoad();
		nftCollection();
	}, []);

	return (
		<>
			<Navbar />
			{isFetching ? (
				<Loader />
			) : (
				<>
					<div className="bg-white mainContainer">
						<section className="">
							<div className="mt-52 flex flex-col md:flex-row gap-12">
								<img
									src={listCat?.data?.image ? `${listCat?.data?.image}` : IMG}
									alt=""
									className=" w-full md:w-96 object-cover md:h-96  h-full"
								/>
								<div className="text-left">
									<h3 className="text-4xl font-bold bannerText">
										{listCat?.data?.name || "Unnamed"}
									</h3>
									<p className="mainText mt-3">
										{listCat?.data?.wallet_address}
									</p>

									<p className="mainText text-gray-500 mt-6 ">
										{listCat?.data?.description}
									</p>
								</div>
							</div>
						</section>
						<section>
							<div className="flex items-center justify-between">
								<h2 className="mt-4 text-4xl font-bold text-first bannerText">
									Artist NFTs
								</h2>
							</div>
							{nftList?.data?.length === 0 && <NoData />}
							{isFetchingNft && <Loader />}
							<div className="nft-collection mb-16">
								{nftList?.data?.length > 0 &&
									nftList?.data?.map((item) => {
										return (
											<CollectionCard
												item={item}
												artist={listCat?.data?.name}
												url={`/product/${item?.nft_data[0]._id}`}
											/>
										);
									})}
							</div>
						</section>
					</div>
				</>
			)}
			<Footer />
		</>
	);
};

export default ViewCollection;
