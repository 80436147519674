import React from 'react'

import { ThreeDots } from 'react-loader-spinner'

const DotLoader = () => {
    return (
        <div className="flex items-center justify-center w-full">
            <ThreeDots
                visible={true}
                height="20"
                width="60"
                color="#fff"
                radius="2"
                ariaLabel="three-dots-loading"
            />
        </div>
    )
}

export default DotLoader