import React from "react";
import { switchNetwork } from "../utils/functions/chainSwitch";

const NetworkError = ({ network, id }) => {
	return (
		<div className="flex items-center justify-center  fixed	bottom-6 right-6">
			<div className="bg-pink-100 rounded  p-6 flex ">
				<i class="fa-solid fa-triangle-exclamation mr-5 text-5xl text-yellow-500"></i>
				<div>
					<h2 className="font-bold text-3xl text-first">
						Wrong Network Detected
					</h2>
					<p className="text-xl ">Switch to {network} network</p>
					{/* <button
						className="mt-4 bg-yellow-600 px-3 py-2 rounded"
						onClick={async () => await switchNetwork(id)}
					>
						Switch
					</button> */}
				</div>
			</div>
		</div>
	);
};

export default NetworkError;
