import React from "react";

const Web3Loader = () => {
	return (
		<div className=" flex gap-6 items-center rounded-lg bg-gray-100 p-6 w-full">
			<div className="loader"></div>
			<p className="text-2xl text-left text-gray-600 ">
				Do not refresh or reload the page. It will take a short while.
			</p>
		</div>
	);
};

export default Web3Loader;
