import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import HomeHeading from "../components/HomeHeading";
import img from "../assets/img8.JPG";


const WhyInvest = ({ page = false }) => {
  const Grids = ({ title, description, value = "1" }) => {
    return (
      <div className="flex gap-10 w-full items-start">
        <div>
          <span className="h-12 w-12 flex items-center justify-center border-second text-2xl font-bold text-gray-600">
            {value}
          </span>
        </div>
        <p className="text-gray-600 mainText text-left leading-10">
          {description}
        </p>
      </div>
    );
  };
  return (
    <>
      {page && <Navbar />}
      <div className={`${page && "mt-60"} mb-40`} id="creates">
        <HomeHeading
          title="Why Invest in Art?
"
          description="Artwork created by established artists has developed into an attractive asset class. Expanding global wealth, scarcity value, and international marketability of masterpiece artwork can provide investors with attractive risk-adjusted returns, a store of value, and a hedge against inflation and currency movements.
          Post War & Contemporary Art, consisting of works created after 1945, has had an average appreciation rate"
        />
        <div className="mb-20"></div>
        <div className="flex flex-col lg:flex-row site-container gap-12">
          <img src={img} alt="" className="lg:w-1/4 w-full flex-1" />
          <div className="flex flex-col gap-12 m-20 site-container mx-auto">
            <Grids
              value="1"
              description="Attractive risk-adjusted returns. The returns on art have been strong over the last twenty to fifty years—the leading collecting categories, which Artequitys NFT has identified for prospective acquisitions, outperformed the S&P 500 and Financial Times All Share indexes by a factor of 10%–30%."
            />

            <Grids
              value="2"
              description="Strong store of value. Masterpiece artwork benefits from a consistent market demand, even through market down cycles. During the financial crisis of 2008-2009, art markets declined less than 26% from their peak (and recovered shortly thereafter), as compared to the S&P which declined 57%."
            />

            <Grids
              value="3"
              description="Art as a risk diversifier. There is weak correlation to US and global equity markets which makes art an attractive investment from a risk diversification standpoint. MeiMoses calculated a long-term correlation coefficient of 0.04 for art with the S&P 500.2 Similar analyses indicate that art market returns are only marginally correlated with equity markets on a lagged basis. Scarcity value of masterpiece artwork results in investment characteristics similar to gold, commodities, luxury real estate and other inflation-protected tangible assets. Similarly, art provides a natural hedge against cross- currency movements given global collector demand and can be bought and sold in any currency."
            />

            <Grids
              value="4"
              description=" Driven by growth in global ultra-high-net- worth-individuals wealth. Art is among the top passions of the ultra-high-net-worth (UHNWI) community which drives demand for blue-chip artwork. The number of millionaires has increased 170% since 2000 and number of UHNWIs has risen by nearly five-times. Growth in millionaires is expected to rise another 22% by 2022 and 30% amongst UHNWIs over the next five years with a significant contribution coming from emerging economies."
            />

            <Grids
              value="5"
              description="A global market. The art market is not country-specific nor bound by geography. Major auction sales are held each year in New York, London and Hong Kong with the US holding the highest share of the art market at 41% ,which has increased from prior years. Likewise, major art fairs draw collectors year-round from around the world."
            />
          </div>
        </div>
      </div>
      {page && <Footer />}
    </>
  );
};

export default WhyInvest;
