import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { usePurchaseNftMutation } from "../services/apis";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { Errors } from "../utils/Errors.js";
import Web3Loader from "../components/Web3Loader";
import { useNftByIdQuery } from "../services/apis";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "../components/Modal/Modal";
import { useSelector } from "react-redux";
import { PopUp } from "../utils/alert";
import { ethers } from "ethers";

import Loader from "../components/Loader";
import Timer from "../components/Timer";
import { TOKEN_NAME, USD } from "../utils/constants";
import WebError from "../components/WebError.jsx";
import ShopInfo from "../components/ShopInfo.jsx";
import NetworkError from "../components/NetworkError.jsx";
import DotLoader from "../components/DotLoader.jsx";
import { ThreeDots } from "react-loader-spinner";
import imgs from "../assets/image/nft1.jpeg";

const Product = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const profileData = useSelector((state) => state.constants.profileData);
  const walletInfo = useSelector((state) => state.constants.walletInfo);
  const loginState = useSelector((state) => state.constants.loginState);
  const chainType = useSelector((state) => state.constants.chainType);

  const { data, refetch, isFetching, isError } = useNftByIdQuery(id);

  const availItem =
    data?.data?.token_owner?.on_sale_fractions -
    (data?.data?.token_owner?.fraction_amount -
      data?.data?.token_owner?.remaining_fraction);

  const [loader, setLoader] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [descShow, setDescShow] = useState(false);

  const [showVideo, setShowVideo] = useState(1);

  const [fractionAmount, setFractionAmount] = useState(false);
  const [txnHash, setTxnHash] = useState("");
  const [artShow, setArtShow] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [typeSwitch, setTypeSwitch] = useState("");
  const [percent, setPercent] = useState(0);

  const [purchaseNft, { data: nftPurchase, isError: purchaseErr }] =
    usePurchaseNftMutation();

  const nftPrice = data?.data?.token_owner?.per_fraction_price * fractionAmount;

  const chainId =
    data?.data?.blockchain === "MATIC"
      ? 80002
      : data?.data?.blockchain === "ETH"
      ? 11155111
      : 97;

  useEffect(() => {
    refetch();
  }, [id]);

  useEffect(() => {
    if (isError) {
      PopUp(
        "Under Maintenance",
        "We are scheduled on maintenance. please check after some time",
        "warning"
      );
    }
  }, [isError]);

  const [tokenBalance, setTokenBalance] = useState(null);

  async function getTokenBalance() {
    const tokenAddress = chainType?.wethAddress;
    const userAddress = window.ethereum.selectedAddress;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const tokenContract = new ethers.Contract(
      tokenAddress,
      ["function balanceOf(address) view returns (uint256)"],
      provider
    );
    try {
      const balance = await tokenContract.balanceOf(userAddress);
      setTokenBalance(balance);
    } catch (error) {
      console.error("Error fetching token balance", error);
    }
  }

  useEffect(() => {
    if (data && data?.data.hasOwnProperty("token_owner") === false) {
      navigate("/");
    }
  }, [data]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getTokenBalance();
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const purchaseApproval = async () => {
    setLoader(true);
    try {
      const ethereumProvider = new ethers.providers.Web3Provider(
        window.ethereum
      );
      let signer = ethereumProvider.getSigner();
      let token = new ethers.Contract(
        chainType?.wethAddress,
        chainType?.wethAbi,
        signer
      );
      let tx = await token.approve(
        chainType?.fractionAddress,
        ethers.utils.parseEther(
          (data?.data?.token_owner?.per_fraction_price * fractionAmount)
            .toFixed(7)
            .toString()
        ),
        {
          gasLimit: 100000,
        }
      );
      const result = await tx.wait();
      if (result?.status === 1) {
        handlePurchase();
      }
    } catch (err) {
      PopUp("User denied transaction", "", "error");
      setLoader(false);
    }
  };

  const handlePurchase = async () => {
    try {
      const ethereumProvider = new ethers.providers.Web3Provider(
        window.ethereum
      );

      let signer = ethereumProvider.getSigner();
      const token = new ethers.Contract(
        chainType?.fractionAddress,
        chainType?.fractionAbi,
        signer
      );
      const tx = await token.purchaseFractionWithERC20(
        chainType?.collectionAddress,
        data?.data?.token_id,
        fractionAmount,
        chainType?.wethAddress,
        {
          gasLimit: 100000,
        }
      );
      const result = await tx.wait();
      setTxnHash(result.transactionHash);
      if (result?.status === 1) {
        handlePurchaseNft();
      }
    } catch (err) {
      Errors(err);
      setLoader(false);
    }
  };

  const buyWithMatic = async () => {
    setLoader(true);
    try {
      const ethereumProvider = new ethers.providers.Web3Provider(
        window.ethereum
      );
      let signer = ethereumProvider.getSigner();
      const token = new ethers.Contract(
        chainType?.fractionAddress,
        chainType?.fractionAbi,
        signer
      );

      const valueToSend = ethers.utils.parseEther(
        (data?.data?.token_owner?.per_fraction_price * fractionAmount)
          .toFixed(7)
          .toString()
      );

      const tx = await token.purchaseFraction(
        chainType?.collectionAddress,
        data?.data?.token_id,
        fractionAmount,
        {
          gasLimit: 1000000,
          value: valueToSend,
        }
      );

      const result = await tx.wait();
      setTxnHash(result.transactionHash);

      if (result?.status === 1) {
        handlePurchaseNft();
      }
    } catch (err) {
      Errors(err);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (nftPurchase?.success) {
      setSuccessShow(true);
      setIsShow(false);
      setFractionAmount("");
      refetch();
    }

    if (nftPurchase?.success === false) {
      PopUp("Something went wrong", "", "error");
    }
  }, [nftPurchase]);

  const handlePurchaseNft = () => {
    purchaseNft({
      token_id: data?.data?._id,
      amount: data?.data?.token_owner?.per_fraction_price * fractionAmount,
      fractionPurchase: Number(fractionAmount),
    });
  };

  useEffect(() => {
    setFractionAmount(
      data?.data?.token_owner?.fixed === 1
        ? data?.data?.token_owner?.amount
        : fractionAmount
    );
  }, [data]);

  const buyNow = (type) => {
    setTypeSwitch(type);

    if (loginState !== 1) {
      PopUp("Please connect to your wallet", "", "error");
      navigate("/");
      return;
    }
    if (profileData?.data?.role !== "user") {
      PopUp(
        "Please connect as a user",
        "In order to buy NFT. you must logged in with User role",
        "error"
      );
      return;
    }
    if (isError) {
      PopUp(
        "Under Maintenance",
        "We are scheduled on maintenance. please check after some time",
        "warning"
      );
      return;
    }
    // if (
    // 	tokenBalance &&
    // 	ethers.utils.formatUnits(tokenBalance, "ether") <
    // 	data?.data?.token_owner?.per_fraction_price * fractionAmount
    // ) {
    // 	PopUp("Insufficient Balance", "", "error");
    // 	setLoader(false);
    // 	return;
    // }
    if (profileData?.data?.is_verified === 0) {
      PopUp(
        "Please verify your email first",
        "verify email from my profile section",
        "error"
      );
      return;
    }
    if (!fractionAmount) {
      PopUp("Please enter item amount", "", "error");
      return;
    }

    if (fractionAmount < 1) {
      PopUp("Fraction amount should not be zero", "", "error");
      return;
    }

    if (
      fractionAmount >
      data?.data?.token_owner?.on_sale_fractions -
        (data?.data?.token_owner?.fraction_amount -
          data?.data?.token_owner?.remaining_fraction)
    ) {
      PopUp(
        "Not enough Items",
        `Only ${
          data?.data?.token_owner?.on_sale_fractions -
          (data?.data?.token_owner?.fraction_amount -
            data?.data?.token_owner?.remaining_fraction)
        } Items are available`,
        "error"
      );
      return;
    }
    setIsShow(true);
  };

  useEffect(() => {
    if (percent) {
      const value = (availItem / 100) * percent;
      setFractionAmount(value);
    }
  }, [percent]);

  return (
    <>
      <Navbar />

      <section className="gallery mt-2 relative" id="gallery">
        <div className="product-container">
          {isFetching ? (
            <Loader />
          ) : (
            <>
              <div
                className="flex productDetail"
                style={{
                  maxWidth: "1200px",
                  width: "95%",
                  margin: "0 auto",
                  gap: "3em",
                }}
              >
                <div className="flex-1">
                  {data?.data?.video && (
                    <div
                      className="flex gap-7 bg-white text-2xl py-2 rounded-lg"
                      style={{ width: "max-content" }}
                    >
                      <h4
                        onClick={() => setShowVideo(1)}
                        className={`${
                          showVideo === 1
                            ? "bg-first text-white px-4 py-1"
                            : "py-1"
                        }`}
                        style={{ cursor: "pointer" }}
                      >
                        <i class="fa-solid fa-image"></i>
                      </h4>
                      <h4
                        className={`${
                          showVideo === 2
                            ? "bg-second text-white px-4 py-1"
                            : "py-1"
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowVideo(2)}
                      >
                        <i class="fa-solid fa-video"></i>
                      </h4>
                    </div>
                  )}
                  {showVideo === 1 && (
                    <div
                      style={{
                        height: "100%",
                        maxHeight: "75vh",
                        width: "100%",
                      }}
                    >
                      <img
                        alt={data?.data?.nft_name}
                        src={data?.data?.nft_media[0]}
                        className="h-full w-full object-cover  cursor-pointer"
                        onClick={() => setArtShow(true)}
                      />
                      <div className="mainBorder text-gray-500 bg-opacity-20 px-4 flex items-center justify-between  text-xl">
                        <p>Click on image to view original size</p>
                        <div>
                          <a href={data?.data?.external_link} target="_new">
                            Link{" "}
                            <i class="fa-solid fa-arrow-up-right-from-square"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                  {showVideo === 2 && (
                    <div>
                      <video src={data?.data?.video} controls auto></video>
                    </div>
                  )}
                </div>
                <div className=" flex-1 md:mt-0">
                  <h1
                    className="text-5xl font-bold mt-10 md:mt-0 mb-10 bannerText"
                    style={{ lineHeight: "1.3em" }}
                  >
                    {data?.data?.nft_name}
                  </h1>
                  <ShopInfo item={data} />
                  <div
                    className=" mainBorder mt-10 bg-white bg-opacity-10"
                    onClick={() => setDescShow((prev) => !prev)}
                  >
                    <div
                      className="flex items-center justify-between m-2 p-3"
                      style={{
                        borderBottom:
                          descShow && "1px solid rgb(255, 255, 255, 0.1)",
                      }}
                    >
                      <h4 className="text-2xl font-bold text-gray-700 ">
                        <i class="fa-solid fa-align-center mr-4"></i>
                        Description
                      </h4>
                      <span className="cursor-pointer text-2xl text-first">
                        {descShow ? (
                          <i class="fa-solid fa-chevron-up"></i>
                        ) : (
                          <i class="fa-solid fa-chevron-down"></i>
                        )}
                      </span>
                    </div>
                    {descShow && (
                      <p
                        style={{
                          padding: "15px",
                          fontSize: "15px",
                        }}
                        className="overflow-auto max-h-72 text-first"
                      >
                        {data?.data?.description}
                      </p>
                    )}
                  </div>

                  <div className="mt-10 mainBorder ">
                    <h4
                      className="text-2xl font-bold text-gray-700 mb-2 mt-2 p-4"
                      style={{
                        borderBottom: "0.5px solid rgb(0, 0, 0, 0.1)",
                      }}
                    >
                      <i class="fa-solid fa-sack-dollar mr-4"></i>
                      CURRENT PRICE
                    </h4>
                    <div className="p-6">
                      <h3 className="text-5xl font-bold text-black mb-1">
                        $ {data?.data?.token_owner?.price}{" "}
                      </h3>
                      <div className="flex gap-6 items-center mt-4">
                        <span className="text-lg font-bold  px-2 py-1 text-gray-900 bg-pink-50">
                          $
                          {data?.data?.token_owner?.price /
                            data?.data?.token_owner?.amount}{" "}
                          Per Fraction
                        </span>{" "}
                        <span className="text-lg font-bold text-gray-600 bg-green-50 px-3 py-1">
                          {(USD * data?.data?.token_owner?.price).toFixed(3)}{" "}
                          ArtE
                        </span>
                        <span className="px-3 py-1 text-lg font-bold text-gray-600 bg-gray-100">
                          {availItem} Fractions Left
                        </span>
                      </div>
                    </div>

                    {data?.data?.token_owner?.on_sale_fractions -
                      (data?.data?.token_owner?.fraction_amount -
                        data?.data?.token_owner?.remaining_fraction) ===
                    0 ? (
                      <div className="bg-red-300 flex items-center justify-center p-6 font-bold m-6 rounded text-3xl text-red-700">
                        NFT SOLD
                      </div>
                    ) : (
                      <>
                        <div className="flex items-center mb-3 mt-2 mx-6">
                          {data?.data?.token_owner?.fixed === 0 && (
                            <>
                              <div className="flex items-center w-full gap-2">
                                <button
                                  onClick={() =>
                                    fractionAmount > 0 &&
                                    setFractionAmount((prev) => prev - 1)
                                  }
                                  className="bg-pink-50 mainBorder font-bold text-4xl py-2.5 px-5"
                                >
                                  -
                                </button>
                                <input
                                  type="number"
                                  className="mainBorder w-full p-3 text-2xl"
                                  min="0"
                                  placeholder="Enter Fraction Value"
                                  value={fractionAmount}
                                  disabled={
                                    data?.data?.token_owner
                                      ?.remaining_fraction === 0
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    setFractionAmount(e.target.value)
                                  }
                                />
                                <button
                                  onClick={() =>
                                    setFractionAmount((prev) => prev + 1)
                                  }
                                  className="bg-green-50 mainBorder font-bold text-4xl py-2.5 px-5"
                                >
                                  +
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="px-6 mb-8 flex gap-5 text-first mt-4">
                          {[10, 25, 50, 100].map((item) => {
                            return (
                              <span
                                onClick={() => setPercent(item)}
                                className="px-4 py-2 mainBorder cursor-pointer txt2"
                              >
                                {item}%
                              </span>
                            );
                          })}
                        </div>

                        <div>
                          <div className="flex gap-6 mx-6 mb-8">
                            <button
                              className="text-2xl bg-second font-bold px-16 py-6 text-white flex-1"
                              onClick={() =>
                                walletInfo?.chainId !== chainId
                                  ? PopUp(
                                      "Please connect to wallet",
                                      "",
                                      "error"
                                    )
                                  : buyNow("MATIC")
                              }
                            >
                              {data?.data?.blockchain === "BNB"
                                ? "BNB"
                                : data?.data?.blockchain === "ETH"
                                ? "ETH"
                                : "MATIC"}{" "}
                              Buy
                            </button>
                            <button
                              className="text-2xl bg-first font-bold px-16 py-6 text-white flex-1"
                              onClick={() =>
                                walletInfo?.chainId !== chainId
                                  ? PopUp(
                                      "Please connect to wallet",
                                      "",
                                      "error"
                                    )
                                  : buyNow("HASHS")
                              }
                            >
                              {TOKEN_NAME} Buy
                            </button>
                          </div>

                          {data?.data?.token_owner?.on_sale_fractions -
                            (data?.data?.token_owner?.fraction_amount -
                              data?.data?.token_owner?.remaining_fraction) !==
                            0 &&
                            profileData?.data?.role === "user" && (
                              <div className="mx-6 mb-8 text-2xl text-gray-600 ">
                                Buy with {TOKEN_NAME} token and get{" "}
                                <span className="text-yellow-500 font-bold">
                                  Commission Discount
                                </span>{" "}
                                on current price.
                              </div>
                            )}
                        </div>
                      </>
                    )}
                  </div>

                  <br />

                  {data?.data?.token_owner?.auction === 1 && (
                    <Timer dateVal={data?.data?.token_owner?.auction_date} />
                  )}
                </div>
              </div>
              {profileData?.data?.role === "user" &&
                walletInfo?.chainId !== chainId && (
                  <NetworkError network={data?.data?.blockchain} id={chainId} />
                )}
            </>
          )}
        </div>
      </section>

      <Footer />

      <Modal show={isShow} onClose={() => setIsShow(false)} title="CHECKOUT">
        <div className="flex items-center justify-center flex-col">
          <div style={{ width: "100%" }}>
            <p className="text-2xl mt-12 text-center mb-12">
              Check you detail carefully. Once purchased can not be reversed.
            </p>
            {typeSwitch === "HASHS" && (
              <div className="text-center font-bold mb-8 bg-pink-100 rounded py-6">
                <h2 className="text-4xl text-pink-600"> Congratulation</h2>
                <p className="text-2xl font-bold text-center">
                  You got 20% discount!
                </p>
              </div>
            )}
            <div className="bg-gray-50 p-6 mb-8">
              <div className="flex items-center justify-between w-full">
                <h3 className="text-xl font-bold text-center mb-4">NFT NAME</h3>
                <p className="text-xl text-center mb-4">
                  {data?.data?.nft_name}
                </p>
              </div>

              <div className="flex items-center justify-between w-full">
                <h3 className="text-xl font-bold text-center mb-4">NFT ID</h3>
                <p className="text-xl text-center mb-4">
                  {data?.data?.token_id}
                </p>
              </div>

              <div className="flex items-center justify-between w-full">
                <h3 className="text-xl font-bold text-center mb-4">
                  BUY FRACTION
                </h3>
                <p className="text-xl text-center mb-4">
                  {fractionAmount} Items
                </p>
              </div>

              <div className="flex items-start justify-between w-full">
                <h3 className="text-xl font-bold text-center mb-4">
                  NFT PRICE
                </h3>
                <p className="text-xl text-center mb-4">
                  {data?.data?.token_owner?.per_fraction_price * fractionAmount}{" "}
                  {data?.data?.blockchain === "BNB"
                    ? "BNB"
                    : data?.data?.blockchain === "ETH"
                    ? "ETH"
                    : "MATIC"}
                </p>
              </div>

              {typeSwitch === "HASHS" && (
                <>
                  <div className="flex items-center justify-between w-full">
                    <h3 className="text-xl font-bold text-center mb-4">
                      DISCOUNT
                    </h3>
                    <p className="text-xl text-red-700 text-center mb-4">
                      - 20%
                    </p>
                  </div>

                  <div className="flex items-center justify-between w-full">
                    <h3 className="text-xl font-bold text-center mb-4">
                      FINAL PRICE
                    </h3>
                    <p className="text-xl text-green-600 text-center mb-4">
                      {(nftPrice * 20) / 100}
                    </p>
                  </div>
                </>
              )}
            </div>

            <div className="grid grid-cols-2 gap-6 mt-6">
              <button
                className="profile-btn2 flex-1"
                style={{ fontSize: "14px" }}
                onClick={() => setIsShow(false)}
              >
                Cancel
              </button>
              <button
                className="profile-btn flex-1"
                style={{ fontSize: "14px" }}
                onClick={() => {
                  if (typeSwitch !== "") {
                    if (typeSwitch === "MATIC") {
                      buyWithMatic();
                    } else {
                      purchaseApproval();
                    }
                  }
                }}
                disabled={loader}
              >
                {loader ? <DotLoader /> : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal show={artShow} isScale onClose={() => setArtShow(false)}>
        <img
          src={
            data?.data?._id === "651043e56458efb78754ba24"
              ? imgs
              : `${data?.data?.nft_media[0]}`
          }
          alt=""
          className="object-contain w-full"
          style={{ height: "90%" }}
        />
      </Modal>
      <Modal show={successShow} onClose={() => setSuccessShow(false)}>
        <div className="py-12">
          <div className="flex items-center justify-center flex-col">
            <i class="fa-solid fa-circle-check text-7xl text-first"></i>
            <h2 className="text-5xl font-bold text-gray-600 mt-6">
              Order Completed
            </h2>
          </div>
          <div>
            <p className="text-center mt-12 text-2xl mx-8">
              You have successfully purchased{" "}
              <span className="font-bold text-pink-600">
                {data?.data?.nft_name}
              </span>{" "}
              from Artequitys
            </p>
          </div>
          <div className="bg-gray-100 rounded mt-20 p-8 flex items-center justify-between mx-8 mb-4">
            <div>
              <h3 className="font-bold text-2xl mb-2">Status</h3>
              <span className="text-green-600 text-xl">Completed</span>
            </div>
            <div>
              <h3 className="font-bold text-2xl mb-2">Transaction ID</h3>
              <span className="text-gray-600 text-xl ">
                {txnHash.slice(0, 10) + "..." + txnHash.slice(-5) ||
                  "Not Available"}
              </span>
            </div>
          </div>
        </div>
      </Modal>

      <Modal show={purchaseErr}>
        <WebError id={txnHash} />
      </Modal>
    </>
  );
};

export default Product;
