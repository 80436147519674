import React, { useState, useEffect } from "react";

const Timer = ({ dateVal }) => {
	// Calculate the target date (three months from today)
	// const targetDate = new Date();
	// targetDate.setMonth(targetDate.getMonth() + 3);

	// State to hold the time remaining
	const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

	// Function to calculate the time remaining
	function calculateTimeRemaining() {
		const now = new Date();
		const targetDate = new Date(dateVal);
		const difference = targetDate.getTime() - now.getTime();

		const days = Math.floor(difference / (1000 * 60 * 60 * 24));
		const hours = Math.floor(
			(difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
		);
		const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((difference % (1000 * 60)) / 1000);

		return { days, hours, minutes, seconds };
	}

	// Update the time remaining every second
	useEffect(() => {
		const timer = setInterval(() => {
			setTimeRemaining(calculateTimeRemaining());
		}, 1000);

		return () => clearInterval(timer);
	}, []);

	return (
		<div>
			{isNaN(timeRemaining.days) ? (
				<div className="auction-box">
					<p>Auction Ended</p>
				</div>
			) : (
				<>
					<h2 className="font-bold mb-4 text-white text-4xl">
						Auction Starts On
					</h2>
					<div className="flex w-full gap-4">
						<div className="text-center rounded-2xl bg-yellow-300 w-24 h-24 flex items-center justify-center flex-col">
							<h2 className="text-5xl font-bold">{timeRemaining.days}</h2>
							<p className="">Days</p>
						</div>
						<div className="text-center rounded-2xl bg-yellow-300 w-24 h-24 flex items-center justify-center flex-col">
							<h2 className="text-5xl font-bold">{timeRemaining.hours}</h2>
							<p className="">Hours</p>
						</div>
						<div className="text-center rounded-2xl bg-yellow-300 w-24 h-24 flex items-center justify-center flex-col">
							<h2 className="text-5xl font-bold">{timeRemaining.minutes}</h2>
							<p className="">Minutes</p>
						</div>
						<div className="text-center rounded-2xl bg-yellow-300 w-24 h-24 flex items-center justify-center flex-col">
							<h2 className="text-5xl font-bold">{timeRemaining.seconds}</h2>
							<p className="">Seconds</p>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default Timer;
