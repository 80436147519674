import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {
	useListCategoryQuery,
	useAddNftMutation,
	usePostGasMutation,
} from "../services/apis";
import { PopUp } from "../utils/alert";
import { ethers } from "ethers";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal/Modal";
import { useSelector } from "react-redux";
import { Errors } from "../utils/Errors";
import WebError from "../components/WebError";
import BlockSwitch from "../components/BlockSwitch";
import DotLoader from "../components/DotLoader";

const AddNft = () => {
	const navigate = useNavigate();
	const walletAddr = useSelector((state) => state?.constants?.walletInfo);
	const profileData = useSelector((state) => state?.constants?.profileData);
	const chainType = useSelector((state) => state?.constants?.chainType);
	const walletInfo = useSelector((state) => state?.constants?.walletInfo);

	const { data: listCat, refetch } = useListCategoryQuery();
	const [addNft, { data, isError }] = useAddNftMutation();
	const [
		postGas,
		{ data: gasData, isLoading, error: gasErr, ieError: GasError },
	] = usePostGasMutation();

	const [image, setImage] = useState("");
	const [imageUrl, setImageUrl] = useState("");
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [link, setLink] = useState("");
	const [category, setCategory] = useState("");
	const [uuid, setUUid] = useState(0);
	const [transactionHash, setTransactionHash] = useState(0);
	const [logShow, setLogShow] = useState(false);

	const [gasRate, setGasRate] = useState("");
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		function generateRandomNumber() {
			let randomNumber =
				Math.floor(Math.random() * (234252345423 - 9) + 77712414123) + 234598;
			return randomNumber;
		}

		var randomNum = generateRandomNumber();
		setUUid(randomNum);
	}, []);

	useEffect(() => {
		refetch();
	}, []);

	const [videoFile, setVideoFile] = useState(null);
	const [video, setVideo] = useState("");

	const handleFileChange = (event) => {
		const file = event.target.files[0];

		if (file && file.type.includes("video")) {
			setVideoFile(URL.createObjectURL(file));
			setVideo(file);
		} else {
			setVideoFile(null);
			alert("Please select a valid video file.");
		}
	};

	const handleImage = (event) => {
		const newFile = event.target.files[0];
		setImage(newFile);
		setImageUrl(URL.createObjectURL(newFile));
	};

	useEffect(() => {
		if (data?.success) {
			handleGas(gasRate, data?.data?._id);
		}
	}, [data]);

	const handleNft = () => {
		const formdata = new FormData();
		formdata.append("collection_id", category);
		formdata.append("nft_name", name);
		formdata.append("external_link", link);
		formdata.append("description", description);
		formdata.append("token_id", uuid);
		formdata.append("nft_media", image);
		formdata.append("transaction_id", transactionHash);
		formdata.append("video", video);
		formdata.append("blockchain", chainType?.name);
		addNft(formdata);
	};

	const handleGas = (gas, id) => {
		postGas({
			token_id: id,
			min_gas_price: Number(gas),
		});
	};

	useEffect(() => {
		if (gasData?.success) {
			PopUp(
				"NFT minted successfully",
				"Now you can put your NFT on sale.",
				"success"
			);
			setLogShow(false);
			setLoader(false);
			navigate("/admin");
		}
		if (GasError) {
			PopUp("Something went wrong", "", "error");
			return;
		}
	}, [gasData, GasError]);

	const minNft = async () => {
		if (profileData?.data?.is_verified === 0) {
			PopUp(
				"Please verify your email first",
				"Go to my profile section",
				"error"
			);
			return;
		}
		if (!image) {
			PopUp("Please select image", "", "error");
			return;
		}

		if (!name) {
			PopUp("Please enter name", "", "error");
			return;
		}

		if (!description) {
			PopUp("Please enter description", "", "error");
			return;
		}

		if (!category) {
			PopUp("Please select category", "", "error");
			return;
		}
		setLoader(true);
		try {
			const ethereumProvider = new ethers.providers.Web3Provider(
				window.ethereum
			);
			let signer = ethereumProvider.getSigner();
			const token = new ethers.Contract(
				chainType?.collectionAddress,
				chainType?.collectionAbi,
				signer
			);

			const tx = await token.safeMint(walletAddr?.address, uuid);
			const result = await tx.wait();
			setTransactionHash(result?.transactionHash);

			if (result?.status === 1) {
				setGasRate(result?.gasUsed?._hex);
				handleNft();
			}
		} catch (err) {
			Errors(err);
			setLoader(false);
		}
	};


	useEffect(() => {
		if (isError) {
			setLogShow(false);
		}
	}, [isError]);

	return (
		<>
			<Navbar />
			<section className="bg-white mt-28 mb-16">
				<div className=" profile-container">
					{profileData?.data?.is_verified === 0 && (
						<div
							className="mt-20 bg-red-300 px-4 py-3 rounded flex items-center justify-between"
							style={{ width: "100%" }}
						>
							<p className="text-2xl">
								Please verify you email in order to create nfts
							</p>
							<button
								className="bg-red-700 px-3 py-2 rounded text-white text-2xl text-bold"
								onClick={() =>
									navigate(`/edit-profile/${profileData?.data?._id}`)
								}
							>
								Verify
							</button>
						</div>
					)}
					<h2 className="text-5xl text-bold text-center mt-16 font-bold bannerText">
						Create New Item
					</h2>

					<form action="">
						<div className="grid grid-cols-1 sm:grid-cols-2 gap-8 my-12">
							<div className="w-full relative">
								<label className="flex justify-center w-full h-96 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
									<span className="flex items-center space-x-2">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="w-6 h-6 text-gray-600"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											strokeWidth={2}
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
											/>
										</svg>
										<span className="text-2xl text-gray-600">
											Upload your NFT image
											{/* <span className="text-blue-600 underline">browse</span> */}
										</span>
										{imageUrl && (
											<img
												src={imageUrl}
												alt=""
												style={{ width: "98%", height: "90%" }}
												className="absolute left-0"
											/>
										)}
									</span>
									<input
										type="file"
										name="file_upload"
										className="hidden"
										onChange={handleImage}
									/>
								</label>
								<p className="mt-3">
									File types supported: JPG, PNG, GIF. Max size: 100 MB
								</p>
							</div>

							<div className="w-full relative">
								<label className="flex justify-center w-full h-96 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
									<span className="flex items-center space-x-2">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="w-6 h-6 text-gray-600"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											strokeWidth={2}
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
											/>
										</svg>
										<span className="text-2xl text-gray-600">
											Upload your NFT Video
											{/* <span className="text-blue-600 underline">browse</span> */}
										</span>
										{videoFile && (
											<div
												style={{ width: "98%", height: "90%" }}
												className="absolute left-0 bottom-0"
											>
												<video src={videoFile} controls>
													Your browser does not support the video tag.
												</video>
											</div>
										)}
									</span>
									<input
										type="file"
										name="file_upload"
										accept="video/*"
										className="hidden"
										onChange={handleFileChange}
									/>
								</label>
								<p className="mt-3">
									File types supported: MP4, MOV, GIF. Max size: 100 MB
								</p>
							</div>
						</div>
						<div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
							<div className="form-box">
								<label htmlFor="">Blockchain Type</label>
								<p className="mb-8">
									It is a type of blockchain. we are supported right now.
								</p>
								<div
									className="w-full py-1"
									style={{ border: "1px solid #e3e3e3", borderRadius: "5px" }}
								>
									{/* <img src={chainType?.img} className="w-20" alt="" />
									<input type="text" value={chainType?.name} disabled /> */}
									<BlockSwitch nft />
								</div>
							</div>
							<div className="form-box">
								<label htmlFor="">TokenId</label>
								<p className="mb-3">
									This is Auto Generated and used for your NFT identification
								</p>
								<input type="text" value={uuid} />
							</div>
						</div>
						<div className="form-box">
							<label htmlFor="">NFT Name</label>
							<input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
						<div className="form-box">
							<label htmlFor="">Description</label>
							<p>
								The description will be included on the item's detail page
								underneath its image.
							</p>
							<textarea
								name=""
								id=""
								cols="30"
								rows="10"
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							></textarea>
						</div>
						<div className="form-box">
							<label htmlFor="">External Link</label>
							<p>
								It will include a link to this URL on this item's detail page,
								so that users can click to learn more about it. Please use
								proper format.
							</p>
							<input
								type="text"
								value={link}
								placeholder="https://example.com/"
								onChange={(e) => setLink(e.target.value)}
							/>
						</div>

						<div className="form-box">
							<label htmlFor="">Category</label>
							<p>This is the Category where your item will appear.</p>

							<select
								onChange={(e) => setCategory(e.target.value)}
							>
								<option className="text-3xl">Select</option>
								{listCat?.data?.length > 0 &&
									listCat?.data?.map((item) => {
										return <option className="m-6 text-3xl txt bg-white" value={item?._id}>{item?.name}</option>;
									})}
							</select>
						</div>
					</form>
					<div className="flex items-cnter gap-6 mt-10">
						<button className="profile-btn2 flex-1">Cancel</button>
						{chainType?.testId === walletInfo?.chainId && (
							<button
								className="profile-btn flex-1"
								onClick={() => setLogShow(true)}
								disabled={loader}
							>
								{loader ? <DotLoader /> : "Submit"}
							</button>
						)}
					</div>
					{walletInfo?.chainId !== undefined &&
						(chainType?.testId === walletInfo?.chainId ? false : true) && (
							<div className="p-6 bg-red-100 text-center rounded">
								<div className="flex items-center  gap-6 justify-center">
									<h2 className="text-3xl font-bold text-red-600 ">
										Wrong network detected
									</h2>
								</div>
								<div>
									<p className="mt-4 text-2xl ">
										please change your network to{" "}
										<span className="font-bold text-pink-600">
											{chainType?.blockChain}
										</span>{" "}
										network
									</p>
								</div>
							</div>
						)}
				</div>
			</section>
			<Modal show={isError}>
				<WebError id={transactionHash} />
			</Modal>
			<Modal show={logShow} onClose={() => setLogShow(false)} title="Please check your detail carefully. after submission NFT detail
							can not be edited.">
				<div className="flex items-center flex-col">
					<div className="bg-gray-100 w-full p-4 flex flex-col gap-6">
						{
							[{
								label: "Token Id:",
								value: uuid,
							}, {
								label: "NFT Name:",
								value: name,
							}, {
								label: "Description:",
								value: description,
							}, {
								label: "Link:",
								value: link,
							}, {
								label: "Artist:",
								value: category,
							}, {
								label: "Blockchain Type:",
								value: <BlockSwitch nft />,
							}].map(ele => {
								return <div key={ele.label} className="flex items-center justify-between text-2xl">
									<span className="font-bold">{ele.label}</span>
									<span>{ele.value || "N/A"}</span>
								</div>
							})
						}
					</div>
					<div className="flex items-center gap-8 mt-6 w-full">
						<button
							className="profile-btn2 flex-1"
							onClick={() => setLogShow(false)}
						>
							Cancel
						</button>
						<button
							className="profile-btn flex-1"
							onClick={minNft}
							disabled={loader}
						>
							{loader ? <DotLoader /> : "Confirm"}
						</button>
					</div>
				</div>
			</Modal>
			<Footer />
		</>
	);
};

export default AddNft;
