import React, { useState } from "react";
import polygon from "../assets/image/plolygon.png";
import bnb from "../assets/image/bnb.png";
import eth from "../assets/image/eth.png";
import { useNavigate } from "react-router-dom";
import {
	collectionAddress,
	fractionAddress,
	WETHTokenAddress,
	collectionAddressETH,
	fractionAddressETH,
	WETHTokenAddressETH,
	fractionAddressBNB,
	collectionAddressBNB,
	WETHTokenAddressBNB,
} from "../utils/web3/address";

import collectionABI from "../utils/web3/collectionABI.json";
import fractionABI from "../utils/web3/fractionABI.json";
import Weth_ABI from "../utils/web3/WETHABI.json";

import collectionABI_ETH from "../utils/web3/eth/collectionEth.json";
import fractionABI_ETH from "../utils/web3/eth/fractionEth.json";
import Weth_ABI_ETH from "../utils/web3/eth/wethEth.json";

import collectionABI_BNB from "../utils/web3/bnb/collectionBnb.json";
import fractionABI_BNB from "../utils/web3/bnb/fractionBnb.json";
import Weth_ABI_BNB from "../utils/web3/bnb/wethBnb.json";

import { setChainType } from "../services/slices/constants";
import { useSelector, useDispatch } from "react-redux";

const BlockSwitch = ({ market = false, nft=false }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const chainType = useSelector((state) => state.constants.chainType);
	const [select, setSelect] = useState([
		{
			id: 1,
			name: "MATIC",
			blockChain: "Polygon",
			img: polygon,
			wethAbi: Weth_ABI,
			wethAddress: WETHTokenAddress,
			testId: 80002,
			mainId: "",
			collectionAddress: collectionAddress,
			fractionAddress: fractionAddress,
			collectionAbi: collectionABI,
			fractionAbi: fractionABI,
		},
		{
			id: 2,
			name: "ETH",
			blockChain: "Ethereum",
			img: eth,
			wethAbi: Weth_ABI_ETH,
			wethAddress: WETHTokenAddressETH,
			testId: 11155111,
			mainId: "",
			collectionAddress: collectionAddressETH,
			fractionAddress: fractionAddressETH,
			collectionAbi: collectionABI_ETH,
			fractionAbi: fractionABI_ETH,
		},
		{
			id: 3,
			name: "BNB",
			blockChain: "Binance",
			img: bnb,
			wethAbi: Weth_ABI_BNB,
			wethAddress: WETHTokenAddressBNB,
			testId: 97,
			mainId: "",
			collectionAddress: collectionAddressBNB,
			fractionAddress: fractionAddressBNB,
			collectionAbi: collectionABI_BNB,
			fractionAbi: fractionABI_BNB,
		},
	]);

	const [show, setShow] = useState(false);


	return (
		<div
			className={`px-6 relative bg-white py-2 mb-1 relative bg-white ${market && "mb-1 mt-0 mainBorder w-1/2 "
				} `}
			style={{ border: nft ? "none" : "1px solid #e3e3e3" }}
		>
			<div
				className="flex items-center gap-5 cursor-pointer"
				onClick={() => setShow((prev) => !prev)}
			>
				<img src={chainType?.img} className="w-12" alt="" />
				<span className="text-xl font-bold">{chainType?.name}</span>
			</div>
			{show && (
				<div
					className="absolute top-20 left-0 shadow-xl w-full bg-white p-3 rounded-lg flex flex-col gap-6 "
					style={{ zIndex: "999", border: "1px solid #e3e3e3" }}
				>
					{select?.length > 0 && select?.map((item) => {
						return (
							<div
								onClick={() => {
									dispatch(setChainType(item));
									setShow(false);
									navigate("/marketplace");
								}}
								className="flex items-center gap-3 hover:text-pink-600 cursor-pointer"
								key={item?.id}
							>
								<img src={item.img} className="w-10" alt="" />
								<span className="text-xl font-bold">{item?.name}</span>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default BlockSwitch;
