import React, { useState } from "react";
import Logo from "../assets/image/logo.png";
import { CopyToClipboard } from "react-copy-to-clipboard";

const WebError = ({
	code = 500,
	title = "Unfortunately our system is not well right now",
	description = "Please copy your hash and contact our customer care. We are happy to help you.",
	id = "",
}) => {
	const [copy, setCopy] = useState(false);
	return (
		<div class="flex items-center justify-center p-20">
			<div class="flex flex-col items-center justify-center ">
				<img src={Logo} alt="" className="w-52" />
				<div class="mb-4">
					<h1 class="text-9xl font-extrabold text-pink-700">{code}</h1>
				</div>
				<h3 class="mb-3 text-4xl font-bold text-center text-gray-700">
					{title}
				</h3>
				<p class="text-xl text-center text-blue-600">{description}</p>
				<div className="flex items-center gap-4 justify-center mt-12 mx-8 border border-2 bg-gray-300 py-2 px-4 rounded">
					<p className="text-xl">{id?.length > 0 && id?.slice(0, 15)}</p>
					<CopyToClipboard text={id} onCopy={() => setCopy(true)}>
						<span>
							{copy ? (
								<i class="fa-solid fa-circle-check ml-6 text-3xl text-green-600"></i>
							) : (
								<i class="fa-regular fa-copy ml-6 text-3xl text-purple-600"></i>
							)}
						</span>
					</CopyToClipboard>
				</div>
			</div>
		</div>
	);
};

export default WebError;
