import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {
  useNftByIdQuery,
  useSaleNftMutation,
  usePostGasMutation,
  useGetAddressStatusQuery,
  useDisableNftMutation,
} from "../services/apis";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { collectionAddress } from "../utils/web3/address";
import { PopUp } from "../utils/alert";
import { ethers } from "ethers";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";
import { Toggle } from "../components/Toggle";
import Modal from "../components/Modal/Modal";
import Web3Loader from "../components/Web3Loader";
import { Errors } from "../utils/Errors";
import WebError from "../components/WebError";
import NetworkError from "../components/NetworkError";
import DotLoader from "../components/DotLoader";

const SellNft = () => {
  const { id } = useParams();
  const chainType = useSelector((state) => state?.constants?.chainType);
  const walletInfo = useSelector((state) => state?.constants?.walletInfo);
  const profileData = useSelector((state) => state.constants.profileData);

  const navigate = useNavigate();
  const { data, refetch, isFetching } = useNftByIdQuery(id);
  const [saleNft, { data: saleData, isError }] = useSaleNftMutation();
  const [
    postGas,
    { data: gasData, isLoading, error: gasErr, isError: GasError },
  ] = usePostGasMutation();

  const { data: addressData, refetch: addressRefetch } =
    useGetAddressStatusQuery(profileData?.data?.wallet_address);

  const [disableNft, { data: hideData }] = useDisableNftMutation();

  const [amount, setAmount] = useState("");
  const [startDate, setStartDate] = useState();

  const [factionPerPrice, setFractionPrice] = useState("");
  const [percentage, setPercentage] = useState("");

  const [loader, setLoader] = useState(false);

  const [fixedToggle, setFixedToggle] = useState(false);
  const [mintToggle, setMintToggle] = useState(false);

  const [auctionToggle, setAuctionToggle] = useState(false);
  const [transactionHash, setTransactionHash] = useState("");
  const [nftPrice, setNftPrice] = useState("");

  const blockChain =
    data?.data?.blockchain === "MATIC"
      ? "Polygon"
      : data?.data?.blockchain === "ETH"
      ? "Ethereum"
      : "Binance";

  const chainId =
    data?.data?.blockchain === "MATIC"
      ? 80002
      : data?.data?.blockchain === "ETH"
      ? 11155111
      : 97;

  useEffect(() => {
    refetch();
    addressRefetch();
  }, []);

  const handleGas = (gas) => {
    postGas({
      token_id: data?.data?._id,
      sell_gas_price: Number(gas),
    });
  };

  useEffect(() => {
    if (gasData?.success) {
      handleSellNft();
    }
    if (gasData?.success === false) {
      handleSellNft();
    }
  }, [gasData, GasError]);

  const handleApprove = async () => {
    if (!amount) {
      PopUp("please enter amount", "", "error");
      return;
    }

    if (!factionPerPrice) {
      PopUp("please enter Fraction Per Price Value", "", "error");
      return;
    }

    if (!percentage) {
      PopUp("please enter Percentage value for sale", "", "error");
      return;
    }

    setLoader(true);

    try {
      const ethereumProvider = new ethers.providers.Web3Provider(
        window.ethereum
      );
      let signer = ethereumProvider.getSigner();
      const token = new ethers.Contract(
        chainType?.collectionAddress,
        chainType?.collectionAbi,
        signer
      );

      const tx = await token.approve(
        chainType?.fractionAddress,
        data?.data?.token_id,
        {
          gasLimit: 100000,
        }
      );
      const result = await tx.wait();

      if (result?.status === 1) {
        handleFraction();
      }
    } catch (err) {
      Errors(err);
      setLoader(false);
    }
  };

  const handleFraction = async () => {
    try {
      const ethereumProvider = new ethers.providers.Web3Provider(
        window.ethereum
      );
      let signer = ethereumProvider.getSigner();
      const token = new ethers.Contract(
        chainType?.fractionAddress,
        chainType?.fractionAbi,
        signer
      );

      const price = parseFloat(factionPerPrice).toFixed(12);

      const collectionAddr = chainType?.collectionAddress;
      const tokenId = data?.data?.token_id;
      const amountValue = ethers.utils.parseEther(amount.toString());
      const priceValue = ethers.utils.parseEther(price.toString());

      let tx;

      if (mintToggle) {
        tx = await token.mintAndFractionalizeWithRole(
          collectionAddr,
          tokenId,
          amountValue,
          amountValue,
          priceValue,
          {
            gasLimit: 1000000,
          }
        );
      } else {
        tx = await token.mintAndFractionalize(
          collectionAddr,
          tokenId,
          amountValue,
          amountValue,
          priceValue,
          {
            gasLimit: 1000000,
          }
        );
      }

      const result = await tx.wait();
      setTransactionHash(result?.transactionHash);
      if (result?.status === 1) {
        handleGas(result?.gasUsed?._hex);
      }
    } catch (err) {
      Errors(err);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (saleData?.success) {
      if (mintToggle) {
        disableNft({
          id: saleData?.data?.token_id,
          is_visible: 0,
        });
      } else {
        PopUp("NFT is on sale successfully", "", "success");
        setLoader(false);
        navigate("/admin");
      }
    }
  }, [saleData]);

  useEffect(() => {
    if (hideData?.success) {
      PopUp("ArtE Assets is Minted successfully", "", "success");
      setLoader(false);
      navigate("/admin");
    }

    if (hideData?.success === false) {
      PopUp("Something went wrong", "", "error");
      setLoader(false);
    }
  }, [hideData]);

  const handleSellNft = () => {
    const obj = {
      id: data?.data?._id,
      price: Number(amount) * Number(factionPerPrice),
      amount: amount,
      fraction_amount: amount,
      per_fraction_price: factionPerPrice,
      on_sale_percent: percentage,
      fixed: fixedToggle ? 1 : 0,
      auction: auctionToggle ? 1 : 0,
      deligation: mintToggle,
    };

    if (auctionToggle) {
      obj.auction_date = startDate;
    }
    saleNft(obj);
  };

  useEffect(() => {
    fixedToggle ? setPercentage(100) : setPercentage("");
  }, [fixedToggle]);

  useEffect(() => {
    mintToggle ? setPercentage(100) : setPercentage("");
  }, [mintToggle]);

  useEffect(() => {
    const perPrice = nftPrice / amount;
    setFractionPrice(perPrice);
  }, [nftPrice, amount]);

  return (
    <>
      <Navbar />
      <section className="bg-white mt-28 pb-32">
        <div className="profile-container ">
          {isFetching ? (
            <Loader />
          ) : (
            <>
              <h2 className="text-5xl font-bold text-bold text-center mt-20 bannerText">
                Sell Your Art
              </h2>
              <div className="mb-10 rounded-xl mt-12">
                <div className="mb-4 flex w-full" style={{ gap: "2em" }}>
                  {data?.data?.nft_media?.length > 0 && (
                    <img
                      src={`${data?.data?.nft_media[0]}`}
                      alt=""
                      className="w-full w-1/2 bg-gray-100 rounded-xl"
                    />
                  )}

                  {data?.data?.video ? (
                    <div className="w-1/2 h-full bg-gray-200 h-full rounded">
                      <video
                        src={data?.data?.video}
                        controls
                        className="w-full bg-gray-100 rounded-xl"
                        style={{ objectFit: "contain" }}
                      >
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  ) : (
                    <div className="w-1/2 bg-gray-200 min-h-[0px] rounded-xl flex items-center justify-center">
                      No Video Available
                    </div>
                  )}
                </div>
                <div className="mt-12">
                  <h2 className="text-3xl font-bold">{data?.data?.nft_name}</h2>
                  <p className="text-xl mt-4 text-gray-600">
                    {data?.data?.description}
                  </p>
                </div>
              </div>
              <div className="h-10"></div>
              <form action="">
                <div className="sell-input mt-6">
                  <div className="form-box">
                    <label htmlFor="">TokenId</label>
                    <p>This is used for your NFT identification</p>
                    <input type="text" value={data?.data?.token_id} disabled />
                  </div>
                  <div className="form-box">
                    <label htmlFor="">Collection Address</label>
                    <p>
                      This will deducted your NFT value and goes to
                      Administrator
                    </p>
                    <input type="text" value={collectionAddress} disabled />
                  </div>
                </div>
                <div className="sell-input">
                  <div className="form-box">
                    <label htmlFor="">NFT Price (Dollar)</label>
                    <p>It is the total price of a NFT</p>
                    <input
                      type="text"
                      value={nftPrice}
                      onChange={(e) => setNftPrice(e.target.value)}
                    />
                  </div>
                  <div className="form-box">
                    <label htmlFor="">Fractions Amount</label>
                    <p>Your desired fractions value for a NFT</p>
                    <input
                      type="number"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      min="0"
                    />
                  </div>
                </div>

                <div className="sell-input">
                  <div className="form-box">
                    <label htmlFor="">Price Per Fraction (Dollar)</label>
                    <p>It is used for per fraction price</p>
                    <input
                      type="number"
                      value={factionPerPrice}
                      min="0"
                      disabled
                    />
                  </div>
                  <div className="form-box">
                    <label htmlFor="">Fractions</label>
                    <p>It will divide your nft into chunks</p>
                    <input type="text" value={amount + " Fractions"} disabled />
                  </div>
                </div>

                <div className="sell-input">
                  <div className="form-box">
                    <label htmlFor="">Blockchain Type</label>
                    <input
                      type="text"
                      min="0"
                      value={blockChain}
                      disabled={true}
                    />
                  </div>
                  <div className="form-box">
                    <label htmlFor="">Item Percentage For Sale (%)</label>
                    <input
                      type="number"
                      min="0"
                      value={percentage}
                      onChange={(e) => setPercentage(e.target.value)}
                      disabled={fixedToggle}
                    />
                  </div>
                </div>
                <div className="sell-input">
                  <div className="form-box">
                    <label htmlFor="">Fixed Price</label>
                    <p className="mb-4">
                      If this enabled, only one user can buy 100% fractions at a
                      time.
                    </p>
                    <Toggle
                      label="Toggle me"
                      toggled={false}
                      onClick={() => setFixedToggle(!fixedToggle)}
                    />
                  </div>

                  <div className="form-box">
                    <label htmlFor="">Auction NFT</label>
                    <p className="mb-4">
                      If this enabled, NFT will be created on Auction.
                    </p>
                    <Toggle
                      label="Toggle me"
                      toggled={false}
                      onClick={() => setAuctionToggle(!auctionToggle)}
                    />
                  </div>
                </div>

                {auctionToggle && (
                  <div className="sell-input">
                    <div className="form-box">
                      <label htmlFor="">Auction Start Date</label>
                      <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                {/* addressData?.data[0]?.isDesignated && */}
                <div className="form-box mt-6">
                  <label htmlFor="">ArtE Minting</label>
                  <p className="mb-4">
                    If this enabled, You can mint your assets without displayed
                    on marketplace.
                  </p>
                  <Toggle
                    label="Toggle me"
                    toggled={false}
                    onClick={() => setMintToggle(!mintToggle)}
                  />
                </div>
              </form>
              {loader && (
                <p className="mt-10 text-green-600 text-2xl">
                  You need to approve two transaction of metamask to complete
                  this process.
                </p>
              )}
              <div className="form-button mt-8 flex items-center gap-8">
                <button className="profile-btn2 flex-1">Cancel</button>
                {chainId === walletInfo?.chainId && (
                  <button
                    className="profile-btn flex-1"
                    onClick={handleApprove}
                    disabled={loader}
                  >
                    {loader ? <DotLoader /> : "Submit"}
                  </button>
                )}
              </div>
            </>
          )}
        </div>
        {chainId !== walletInfo?.chainId && (
          <NetworkError network={data?.data?.blockchain} id={chainId} />
        )}
      </section>
      {/* <Modal show={loader}>
        <div className="flex items-center p-8 justify-center flex-col">
          <Web3Loader />
        </div>
      </Modal> */}
      <Modal show={isError}>
        <WebError id={transactionHash} />
      </Modal>
      <Footer />
    </>
  );
};

export default SellNft;
