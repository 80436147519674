import React from "react";
import Trending from "./Trending";
import Navbar from "./Navbar";
import Footer from "./Footer";
import TrendingCollection from "./TrendingCollection";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useGetBannerQuery } from "../services/apis";
import BannerCard from "./BannerCard";
import CreateSell from "./CreateSell";

const Header = () => {
	const { data, refetch, isFetching } = useGetBannerQuery();

	useEffect(() => {
		refetch();
	}, []);

	return (
		<>
			<Navbar />

			<div

				className="bg-gray-50 pb-32 "
			>
				<div className="pt-60 mb-28   flex items-center gap-28 headerBox site-container">
					<div className="flex-1">
						<h3
							className="text-5xl lg:text-8xl md:text-7xl xl:text-8xl text-black bannerText font-bold"
							style={{ lineHeight: "1.2em" }}
						>
							Explore, Collect and Invest in
							<span className="txt-light bannerText">
								{" "}
								Top-tier Fine Art
							</span>{" "}
							with Blockchain and NFTs
						</h3>
						<p
							className="text-[15px]  text-gray-500 mt-6"
							style={{ lineHeight: "1.3em" }}
						>
							Dive into a revolutionary era of art investment, empowered by the
							fusion of Blockchain technology and NFTs, offering unparalleled
							access and potential in the realm of fine arts."
						</p>
						<Link to="/marketplace">
							<button className="mt-12 bg-first text-white text-2xl px-10 py-4 font-bold">
								Explore <i class="fa-regular fa-circle-right ml-6"></i>
							</button>
						</Link>
						<Link to="/artists">
							<button className="mainBorder mt-12 bg-white text-2xl px-10 py-4 font-bold ml-6 text-gray-900">
								Artists <i class="fa-regular fa-circle-right ml-6"></i>
							</button>
						</Link>
					</div>
					<div className="flex-1 headerNft">
						{data?.data?.length > 0 && (
							<div className="">
								<BannerCard
									data={data?.data[0]?.nft_data}
									home
									isFetching={isFetching}
								/>

							</div>
						)}
					</div>
				</div>
			</div>
			<div>
				<TrendingCollection />
				<Trending />
				<CreateSell />
				<Footer />
			</div>
		</>
	);
};

export default Header;
