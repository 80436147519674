import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import HomeHeading from "../components/HomeHeading";
import img from "../assets/img6.JPG";
import img2 from "../assets/img7.JPG";

const WhyUs = ({ page = false }) => {
  const Grids = ({ title, description, value = "1" }) => {
    return (
      <div className="flex gap-10 w-full items-start">
        <div>
          <span className="h-12 w-12 flex items-center justify-center border-second text-2xl font-bold text-gray-600">
            {value}
          </span>
        </div>
        <p className="text-gray-600 mainText text-left leading-10">
          {description}
        </p>
      </div>
    );
  };

  const Grid2 = ({ title, description, value = "1" }) => {
    return (
      <div className="flex flex-col items-start ">
        <div className="w-12 h-12 flex items-center justify-center bg-second text-2xl font-bold text-white">
          {value}
        </div>
        {/* <h2 className="font-bold text-5xl mb-4 mt-6 text-black text-left bannerText">
          {title}
        </h2> */}
        <p className="text-gray-500 mainText text-left leading-10 mt-6">
          {description}
        </p>
      </div>
    );
  };
  return (
    <>
      {page && <Navbar />}
      <div className={`${page && "mt-60"} mb-40`} id="creates">
        <HomeHeading
          title="Why Artequitys?
"
          description="Art Equity is democratizing a $1 trillion+ asset class that has previously been available only to ultra-wealthy collectors capable of spending millions of dollars to build a portfolio of investment- grade art.

          WE FRACTIONALISE EACH ARTWORK by making NFT shares of each ARTWORK and allow investors to fractionally invest in these artworks which have only been available to ultra-wealthy collectors.
          
          NFTs are embedded on the blockchain, This is how the true owner and provenance of the image can be verified and can’t be ever copied or faked.
          A NFT, or Non-fungible token is a digital asset that represents real-world objects like art, gold or property. NFT’s use blockchain technology to digitally signify ownership, making an NFT more like the title to a asset rather than the asset itself.
          
          By combining Blockchain Technology and Art we at Art Equity have pledged to revolutionize the way assets are traded across the globe.
          "
        />

        <div className="mb-10"></div>
        <p className="mainText site-container">
          Art Equity is a platform that will let people invest as low as 10$
          dollars in an investment-grade art that provides superior
          risk-adjusted returns for all types of investors.
        </p>
        <div className="mt-16"></div>
        <div className="flex flex-col lg:flex-row site-container gap-12">
          <img src={img} alt="" className="flex-1" />
          <div className="flex flex-col gap-12 mt-20 site-container mx-auto">
            <Grids
              value="1"
              description="WE EXPERTLY SELECT AND ACQUIRE WORKS by blue-chip artists that we believe will appreciate into the future while having minimal downside risk."
            />

            <Grids
              value="2"
              description="WE FRACTIONALISE EACH ARTWORK by making NFT shares of each ARTWORK and allow investors to fractionally invest in these great paintings."
            />

            <Grids
              value="3"
              description="WE’RE BUILDING NFT TRADING MARKET for investors to trade their NFT shares amongst one another, this gives investors an option to liquidate their NFT shares whenever they want."
            />

            <Grids
              value="4"
              description="INVEST AS LOW AS 10 $ in an investment-grade art."
            />

            <Grids
              value="5"
              description="ACHIEVE SUPERIOR RISK-ADJUSTED RETURNS with an asset class that has consistently beaten the S&P 500 and has a low correlation to public equities."
            />

            <Grids
              value="6"
              description="GAIN INVESTMENT EXPOSURE TO THE HIGH- END ART MARKET through tradable NFT shares backed by a single physical work of art."
            />

            <Grids
              value="7"
              description="RELY ON MARKET EXPERTS to source, purchase, and select investment-grade objects."
            />
          </div>
        </div>

        <div className="mb-32"></div>
        <HomeHeading
          title="How Does it Works?"
          description=".
          "
        />
        <div className="flex flex-col lg:flex-row gap-12 site-container">
          <div className="grid grid-cols-2 sm:grid-cols-2 gap-12  md:grid-cols-2 xl:grid-cols-3 site-container text-center mx-auto">
            <Grid2
              title="Curated by experts"
              value="1"
              description="We source artwork that meet our investment criteria."
            />

            <Grid2
              title="Curated by experts"
              value="2"
              description="We purchase artwork with our own capital, taking principal risk."
            />

            <Grid2
              title="Curated by experts"
              value="3"
              description="We fractionalise each artwork by making NFT shares of each artwork."
            />

            <Grid2
              title="Curated by experts"
              value="4"
              description="We sell the fractionalized NFT of artwork partly to retail investors."
            />

            <Grid2
              title="Curated by experts"
              value="5"
              description="We will have a NFT share trading platform so investors can trade their NFT shares anytime they want."
            />

            <Grid2
              title="Curated by experts"
              value="6"
              description="Through our NFT share trading platform investors will have the option sell or buy even 1 NFT share of the artwork."
            />

            <Grid2
              title="Curated by experts"
              value="7"
              description="We hold the artwork for 3-10 years but if a collector makes an offer to buy the artwork."
            />

            <Grid2
              title="Curated by experts"
              value="8"
              description=" The painting is sold and the proceeds are distributed to the NFT shareholders."
            />
          </div>
          <img src={img2} alt="" className="flex-1" />
        </div>
      </div>
      {page && <Footer />}
    </>
  );
};

export default WhyUs;
