import React, { useState } from "react";
import { Link } from "react-router-dom";

const ShopInfo = ({ item }) => {
  const [show, setShow] = useState(false);

  const InfoRow = ({ title, value }) => {
    return (
      <div
        className="flex items-center justify-between mb-4 font-bold pb-4"
        style={{ borderBottom: "1px solid rgb(0, 0, 0, 0.1)" }}
      >
        <h6 className="text-xl text-gray-500 " style={{ marginTop: "0px" }}>
          {title}
        </h6>
        <h5 className=" text-xl text-first">{value}</h5>
      </div>
    );
  };

  return (
    <div className="w-full  mainBorder bg-white ">
      <div
        className="flex items-center justify-between m-2 p-3 "
        style={{
          borderBottom: show && "1px solid rgb(0, 0, 0, 0.1)",
        }}
        onClick={() => setShow((prev) => !prev)}
      >
        <h4 className="text-2xl font-bold text-gray-700 ">
          <i class="fa-solid fa-circle-info mr-4"></i>
          NFT INFO
        </h4>
        <span className="cursor-pointer text-2xl text-first">
          {show ? (
            <i class="fa-solid fa-chevron-up"></i>
          ) : (
            <i class="fa-solid fa-chevron-down"></i>
          )}
        </span>
      </div>
      {show && (
        <div className="p-6">
          <InfoRow title="NFT ID" value={item?.data?.token_id} />
          <InfoRow
            title="NFT Type"
            value={item?.data?.token_owner?.fixed === 1 ? "Gross" : "Fractions"}
          />
          <InfoRow title="Token Standard" value="ERC-721" />
          <InfoRow
            title="Chain"
            value={`${
              item?.data?.blockchain === "BNB"
                ? "Binance"
                : item?.data?.blockchain === "ETH"
                ? "Ethereum"
                : "Polygon"
            }`}
          />
          <InfoRow
            title="Total Quantity"
            value={item?.data?.token_owner?.amount + " Fractions"}
          />
          <InfoRow
            title="Available Fractions"
            value={
              item?.data?.token_owner?.on_sale_fractions -
              (item?.data?.token_owner?.fraction_amount -
                item?.data?.token_owner?.remaining_fraction)
            }
          />
          <InfoRow
            title="Fractions Sold"
            value={
              item?.data?.token_owner?.amount -
              item?.data?.token_owner?.remaining_fraction
            }
          />
          <InfoRow
            title="Price per Fraction"
            value={
              "$" +
              item?.data?.token_owner?.price / item?.data?.token_owner?.amount
            }
          />

          <div className="flex items-center justify-between mt-4">
            <h6 className="text-2xl text-gray-500 font-bold ">Collection</h6>
            <Link to={`/collection/${item?.data?.collection?._id}`}>
              <h5 className="text-2xl text-first underline">
                {item?.data?.collection?.name}
              </h5>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShopInfo;
