import React from "react";
import NoImg from "../assets/image/nf.svg";

const NoData = ({ market }) => {
	return (
		<div className="flex items-center justify-center w-full p-32">
			<div className="text-center py-40  ">
				<img
					src={NoImg}
					className="w-60"
					style={{ background: "transparent" }}
					alt=""
				/>

				<p className={`text-4xl mt-10 ${market ? "text-white" : "text-black"}`}>
					No Items Found!
				</p>
			</div>
		</div>
	);
};

export default NoData;
