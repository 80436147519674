import React from "react";
import {
	useGetOnsaleNftByCreatorQuery,
	useGetNftByCreatorQuery,
} from "../services/apis";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../components/Card";
import NoData from "../components/NoData";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

const MintedNfts = () => {
	const { data, refetch, isFetching } = useGetNftByCreatorQuery();
	const [search, setSearch] = useState("");
	const [list, setList] = useState([]);
	const [saleList, setSaleList] = useState([]);
	const [sold, setSold] = useState([]);
	const [reload, setReload] = useState(true);

	const [saleSearch, setSaleSearch] = useState("");
	const [tab, setTab] = useState(1);
	const { data: saleData, refetch: saleRefetch } =
		useGetOnsaleNftByCreatorQuery();

	useEffect(() => {
		refetch();
		saleRefetch();
	}, [reload]);

	useEffect(() => {
		setList(data?.data);
	}, [data]);

	useEffect(() => {
		setSaleList(saleData?.data);
	}, [saleData]);

	useEffect(() => {
		const list = saleData?.data?.filter(
			(item) => item?.remaining_fraction === 0
		);
		setSold(list);
	}, [saleData]);

	useEffect(() => {
		const updatedList = data?.data?.filter((obj) =>
			obj?.nft_name?.toLowerCase().includes(search)
		);
		setList(updatedList);
	}, [search]);

	useEffect(() => {
		const updatedList = saleData?.data?.filter((obj) =>
			obj?.["nft-data"].nft_name?.toLowerCase().includes(saleSearch)
		);
		setSaleList(updatedList);
	}, [saleSearch]);

	const handleReload = (id) => {
		setReload(id);
	};


	return (
		<section>
			{isFetching ? (
				<div className="flex w-full items-center h-96 justify-center">
					<h5 className="text-3xl">Loading...</h5>
				</div>
			) : (
				<>
					<div className="grid grid-cols-2 gap-12 shopBorder">
						<div className="grid grid-cols-3 gap-6">
							<h2
								className="text-center py-4 px-6 mainBorder text-2xl font-bold cursor-pointer "
								style={{
									background: tab === 1 ? "#e75480" : "white",
									color: tab === 1 ? "white" : "black",
								}}
								onClick={() => setTab(1)}
							>
								Minted
							</h2>
							<h2
								className=" text-center  text-2xl py-4 px-6 mainBorder font-bold cursor-pointer"
								style={{
									background: tab === 2 ? "#e75480" : "white",
									color: tab === 2 ? "white" : "black",
								}}
								onClick={() => setTab(2)}
							>
								On Sale
							</h2>
							<h2
								className=" text-center  text-2xl py-4 px-6 mainBorder font-bold cursor-pointer"
								style={{
									background: tab === 3 ? "#e75480" : "white",
									color: tab === 3 ? "white" : "black",
								}}
								onClick={() => setTab(3)}
							>
								Sold
							</h2>
						</div>

						<div>
							{tab === 1 && (
								<input
									type="text"
									placeholder="Search NFT by Name"
									className="p-4 text-2xl w-full searchBorder"
								
									value={search}
									onChange={(e) => setSearch(e.target.value)}
								/>
							)}

							{tab === 2 && (
								<input
									type="text"
									placeholder="Search NFT by Name"
									className="p-4 text-2xl w-full searchBorder"
									value={saleSearch}
									onChange={(e) => setSaleSearch(e.target.value)}
								/>
							)}
						</div>
					</div>

					{tab === 1 && (
						<>
							{list?.length === 0 ? (
								<NoData />
							) : (
								<div className="nft-collection">
									{list?.length > 0 &&
										list?.map((item) => {
											return (
												<>
													<Card item={item} url={`/sell/${item?._id}`} />
												</>
											);
										})}
								</div>
							)}
						</>
					)}

					{tab === 2 && (
						<>
							{saleList?.length === 0 ? (
								<NoData />
							) : (
								<div className="nft-collection">
									{saleList?.length > 0 &&
										saleList?.map((item) => {
											return (
												<>
													<Card
														item={item?.["nft-data"]}
														itemData={item}
														url={`/product/${item?.token_id}`}
														sale
														reload={handleReload}
													/>
												</>
											);
										})}
								</div>
							)}
						</>
					)}

					{tab === 3 && (
						<>
							{sold?.length === 0 ? (
								<NoData />
							) : (
								<div className="nft-collection">
									{sold?.length > 0 &&
										sold?.map((item) => {
											return (
												<>
													<Card
														item={item?.["nft-data"]}
														url={`/product/${item?.token_id}`}
														sold
													/>
												</>
											);
										})}
								</div>
							)}
						</>
					)}
				</>
			)}
		</section>
	);
};

export default MintedNfts;
