import React from "react";
import HomeHeading from "../components/HomeHeading";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const ComingSoon = () => {
    const Grids = ({ title, description, value = "1" }) => {
        return (
            <div className=" p-8 flex flex-col items-start">
                <div className="w-20 h-20 flex items-center justify-center bg-second text-6xl font-bold text-white">
                    {value}
                </div>
                <h2 className="font-bold text-5xl mb-4 mt-6 text-black text-left bannerText">{title}</h2>
                <p className="text-gray-500 text-2xl text-left leading-10">{description}</p>
            </div>
        );
    };
    return (
        <>
            <Navbar />
            <div className={`mt-60`} id="creates">

                <h3 className="bannerText text-6xl text-first text-center py-60 mt-20">Coming Soon</h3>

            </div>

            <Footer />
        </>
    );
};

export default ComingSoon;
