//Polygon
export const WETHTokenAddress = "0x63aFC5540FAe7D6ef1f0EB557F6B61b9b332a95c";
export const collectionAddress = "0x5579C339317cA2C75Baf52055baF1C6CeBFfD729";
export const fractionAddress = "0x50A3E6593df557E600f453d8009032cc3a4E7999";
export const admin = "0x6E609583D52E150B86fe656f425d1eDF3D0b77CD";

// EThereum
export const WETHTokenAddressETH = "0x26684B1b5d26D54BC0c49a58157f53ce9c6f07C1";
export const collectionAddressETH =
	"0xd02C3901b57A6715291FdF476190C228e0f4a82F";
export const fractionAddressETH = "0xd628056d3963d9649E76551A17eeEbc90c52Ff01";

// Binance
export const WETHTokenAddressBNB = "0x63aFC5540FAe7D6ef1f0EB557F6B61b9b332a95c";
export const collectionAddressBNB =
	"0x5579C339317cA2C75Baf52055baF1C6CeBFfD729";
export const fractionAddressBNB = "0xd02C3901b57A6715291FdF476190C228e0f4a82F";
