import BNB from "../assets/image/bnb.png";
import ETH from "../assets/image/eth.png";
import MATIC from "../assets/image/plolygon.png";

const ChainCheck = ({ itemValue }) => {
	return (
		<>
			<div
				className="shadow-3xl absolute rounded-full bottom-4 right-4"
				title={`${
					itemValue === "BNB"
						? "Binance"
						: itemValue === "ETH"
						? "Ethereum"
						: "Polygon"
				}`}
			>
				{itemValue === "BNB" ? (
					<img
						src={BNB}
						alt=""
						className="rounded-full w-40"
						style={{ width: "30px", height: "30px" }}
					/>
				) : itemValue === "ETH" ? (
					<img
						src={ETH}
						alt=""
						style={{ width: "30px", height: "30px" }}
						className="rounded-full h-12 w-12"
					/>
				) : (
					<img
						src={MATIC}
						alt=""
						style={{ width: "30px", height: "30px", borderRadius: "50%" }}
						className="rounded-full h-12 w-12"
					/>
				)}
			</div>
		</>
	);
};

export default ChainCheck;
