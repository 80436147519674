import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ProductCard from "../components/ProductCard";
import { useListCategoryQuery } from "../services/apis";
import NoData from "../components/NoData";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
const AllCollection = () => {
	const { data, refetch, isFetching } = useListCategoryQuery();

	const [searchQuery, setSearchQuery] = useState("");
	const [filteredItems, setFilteredItems] = useState([]);
	const [noItemsFound, setNoItemsFound] = useState(false);
	const [filter, setFilter] = useState(false);

	useEffect(() => {
		setFilteredItems(data?.data);
	}, [data]);

	useEffect(() => {
		refetch();
	}, []);

	const handleSearchChange = (e) => {
		const value = e.target.value;
		setSearchQuery(value);
		filterItems(value);
	};

	const filterItems = (query) => {
		const filtered = data?.data.filter((item) =>
			item.name.toLowerCase().includes(query.toLowerCase())
		);
		setFilteredItems(filtered);
		setNoItemsFound(filtered.length === 0);
	};

	return (
		<>
			<Navbar />
			<div
				className="pt-40"
				style={{
					maxWidth: "1400px",
					width: "95%",
					display: "flex",
					margin: "1em auto",
				}}
			>
				<div style={{ width: "100%" }}>
					<section className="gallery" id="gallery">
						<div className="mb-16 mt-8 text-center">
							<h2 className="text-6xl text-left font-bold text-center text-first bannerText ">
								Artists
							</h2>
							<p className="text-2xl mt-6 leading-10 text-center text-gray-700 text-left">
								The Artequitys team select the best of what the platform has to
								offer and arrange them into carefully arranged hand picked
								collections for review and purchase.
							</p>
						</div>
						<div className="flex items-center marketSearch w-full  grid grid-cols-1 md:grid-cols-2 gap-8">
							<div className="text-2xl text-black px-2 py-4 searchBorder flex items-center">
								<i class="fa-solid fa-magnifying-glass pr-2 text-first"></i>
								<input
									type="text"
									placeholder="Search Artist by Name"
									className="w-full ml-3 "
									value={searchQuery}
									onChange={handleSearchChange}
								/>
							</div>
						</div>
						<div className="container">
							{isFetching ? (
								<Loader />
							) : (
								<div className="nft-collections">
									{filteredItems?.length > 0 &&
										filteredItems?.map((item) => {
											return (
												<div
													className="swiper-slide box pb-8"
													style={{
														backgroundColor: "white",
													}}
												>
													<Link to={`/collection/${item?._id}`}>
														<img
															src={`${item?.image}`}
															alt
															style={{
																height: "250px",
																width: "100%",
															}}
															className="imgs"
														/>
														<h3
															style={{
																textAlign: "left",
																color: "black",
																padding: ".4em 0em 0 0",
															}}
															className="font-bold hover:text-first text-3xl txt bannerText"
														>
															{item?.name?.length > 25
																? item?.name?.slice(0, 25) + "..."
																: item?.name}
														</h3>
														<div className="text-left mt-1 text-lg text-gray-500 uppercase">{item?.country} - <span className="text-first">{item?.dob}</span></div>

													</Link>
												</div>
											);
										})}
								</div>
							)}
						</div>
					</section>
					{filteredItems?.length === 0 && <NoData market />}
				</div>
			</div>
			{/* </section> */}
			<Footer />
		</>
	);
};

export default AllCollection;
