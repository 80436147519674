import { PopUp } from "./alert";

export function Errors(err) {
	if (err.code === "ACTION_REJECTED") {
		PopUp("Something went wrong!", "User reject signing", "error");
		return;
	}
	PopUp("Something went wrong!", `${err.message}`, "error");
	return;
}
