import { useState } from "react";

export const Toggle = ({ label, toggled, onClick }) => {
	const [isToggled, toggle] = useState(toggled);

	const callback = () => {
		toggle(!isToggled);
		onClick(!isToggled);
	};

	return (
		<label className="label-toggle">
			<input
				className="input-toggle"
				type="checkbox"
				checked={isToggled}
				onClick={callback}
			/>
			<span className="span-toggle" />
		</label>
	);
};
