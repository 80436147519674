import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { decryptData } from "../utils/encrypto";
const baseQuery = fetchBaseQuery({
  // baseUrl: "http://localhost:4000",
  baseUrl: "https://artequitys.com:4000",
  prepareHeaders: (headers) => {
    const token = sessionStorage.getItem("token");
    let decryptToken;
    if (token) {
      decryptToken = decryptData(token);
    }
    headers.set("Authorization", `Bearer ${decryptToken}`);
  },
});
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.data && result?.error?.data?.message === "Token Expired") {
    localStorage.clear();
  }
  return result;
};
export const fractoApis = createApi({
  reducerPath: "fractoApis",
  baseQuery: baseQueryWithReauth,
  refetchOnMountOrArgChange: 1000,
  keepUnusedDataFor: 0,
  skip: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    connect: builder.mutation({
      query: (data) => ({
        url: "/user/user",
        method: "POST",
        body: data,
      }),
    }),
    addCategory: builder.mutation({
      query: (data) => ({
        url: "/collection/addCollection",
        method: "POST",
        body: data,
      }),
    }),

    editCollection: builder.mutation({
      query: (data) => ({
        url: "/collection/editCollection",
        method: "POST",
        body: data,
      }),
    }),

    sendOtp: builder.mutation({
      query: (data) => ({
        url: "/user/sendOtp",
        method: "POST",
        body: data,
      }),
    }),

    disableNft: builder.mutation({
      query: (data) => ({
        url: "/nft/enableDisableVisibility",
        method: "POST",
        body: data,
      }),
    }),

    newsletter: builder.mutation({
      query: (data) => ({
        url: "/address/newsletter",
        method: "POST",
        body: data,
      }),
    }),

    editProfile: builder.mutation({
      query: (data) => ({
        url: "/user/editUserProfile",
        method: "PUT",
        body: data,
      }),
    }),

    addPrivacy: builder.mutation({
      query: (data) => ({
        url: "/cms/addPrivacyPolicy",
        method: "PUT",
        body: data,
      }),
    }),

    addTerms: builder.mutation({
      query: (data) => ({
        url: "/cms/addTermsAndCondition",
        method: "PUT",
        body: data,
      }),
    }),

    addLegal: builder.mutation({
      query: (data) => ({
        url: "/cms/addHelpAndGuidance",
        method: "PUT",
        body: data,
      }),
    }),

    addNft: builder.mutation({
      query: (data) => ({
        url: "/nft/nft",
        method: "POST",
        body: data,
      }),
    }),

    disconnect: builder.mutation({
      query: (data) => ({
        url: "/user/disconnect",
        method: "POST",
        body: data,
      }),
    }),

    saleNft: builder.mutation({
      query: (data) => ({
        url: "/nft/sale-nft",
        method: "POST",
        body: data,
      }),
    }),

    verifyOtp: builder.mutation({
      query: (data) => ({
        url: "/user/verify",
        method: "POST",
        body: data,
      }),
    }),

    deleteUser: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `/user/deleteUser?id=${id}`,
          method: "DELETE",
          body,
        };
      },
    }),

    postGas: builder.mutation({
      query: (data) => ({
        url: "/nft/addNftPrice",
        method: "POST",
        body: data,
      }),
    }),

    blockUser: builder.mutation({
      query: (data) => ({
        url: "/user/blockAndUnblockUser",
        method: "POST",
        body: data,
      }),
    }),

    verifyRequest: builder.mutation({
      query: (data) => ({
        url: "/address/verification-request",
        method: "POST",
        body: data,
      }),
    }),

    listGas: builder.query({
      query: () => ({
        url: "/nft/getListNftPrice",
        method: "GET",
      }),
    }),

    listCategory: builder.query({
      query: () => ({
        url: "/collection/allCollections",
        method: "GET",
      }),
    }),

    getNftByCreator: builder.query({
      query: () => ({
        url: "/nft/listCreatorAllNft",
        method: "GET",
      }),
    }),

    getOnsaleNftByCreator: builder.query({
      query: () => ({
        url: "/nft/listCreatorOnSaleAllNft",
        method: "GET",
      }),
    }),

    nftInCollection: builder.query({
      query: (data) => ({
        url: `/collection/listNftInCollection?collection_id=${data}`,
        method: "GET",
      }),
    }),

    nftList: builder.query({
      query: () => ({
        url: "nft/listNft",
        method: "GET",
      }),
    }),

    userList: builder.query({
      query: () => ({
        url: "/user/listAllUsers",
        method: "GET",
      }),
    }),

    getProfile: builder.query({
      query: (data) => ({
        url: `/user/getUserProfile?id=${data}`,
        method: "GET",
      }),
    }),

    getPrivacy: builder.query({
      query: (data) => ({
        url: `/cms/getPrivacyPolicy`,
        method: "GET",
      }),
    }),

    getTerm: builder.query({
      query: (data) => ({
        url: `/cms/getTermsAndCondition`,
        method: "GET",
      }),
    }),

    getLegal: builder.query({
      query: (data) => ({
        url: `/cms/getHelpAndGuidance`,
        method: "GET",
      }),
    }),

    listAllNft: builder.query({
      query: (data) => ({
        url: `nft/listNft`,
        method: "GET",
      }),
    }),

    nftById: builder.query({
      query: (data) => ({
        url: `/nft/nft-data?id=${data}`,
        method: "GET",
      }),
    }),

    collectionById: builder.query({
      query: (data) => ({
        url: `/collection/collection?id=${data}`,
        method: "GET",
      }),
    }),

    purchaseNft: builder.mutation({
      query: (data) => ({
        url: "/nft/purchase",
        method: "POST",
        body: data,
      }),
    }),

    addBanner: builder.mutation({
      query: (data) => ({
        url: "/nft/addBannerNft",
        method: "POST",
        body: data,
      }),
    }),

    marketplace: builder.query({
      query: (data) => ({
        url: `nft/onSale`,
        method: "GET",
      }),
    }),

    userNfts: builder.query({
      query: (data) => ({
        url: `nft/listAllUserNftOnSale?user_id=${data}`,
        method: "GET",
      }),
    }),

    getBanner: builder.query({
      query: (data) => ({
        url: `nft/getBannerNft`,
        method: "GET",
      }),
    }),

    getAddressStatus: builder.query({
      query: (data) => ({
        url: `address/${data}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useConnectMutation,
  useGetAddressStatusQuery,
  useVerifyRequestMutation,
  useAddCategoryMutation,
  useListCategoryQuery,
  useUserListQuery,
  useAddNftMutation,
  useAddLegalMutation,
  useGetLegalQuery,
  useNftListQuery,
  useEditProfileMutation,
  useGetProfileQuery,
  useDisconnectMutation,
  useCollectionByIdQuery,
  useEditCollectionMutation,
  useNewsletterMutation,
  useNftByIdQuery,
  useSaleNftMutation,
  useMarketplaceQuery,
  usePurchaseNftMutation,
  useNftInCollectionQuery,
  useUserNftsQuery,
  useAddPrivacyMutation,
  useGetPrivacyQuery,
  useAddTermsMutation,
  useGetTermQuery,
  useGetNftByCreatorQuery,
  useGetOnsaleNftByCreatorQuery,
  useListAllNftQuery,
  useAddBannerMutation,
  useGetBannerQuery,
  useSendOtpMutation,
  useVerifyOtpMutation,
  usePostGasMutation,
  useListGasQuery,
  useDisableNftMutation,
  useDeleteUserMutation,
  useBlockUserMutation,
} = fractoApis;
