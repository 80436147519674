import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import IMG from "../assets/image/user-dummy.png";
import NA from "../assets/image/na.svg";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useGetProfileQuery, useUserNftsQuery } from "../services/apis";
import moment from "moment";
import { setLoginState } from "../services/slices/constants";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import { TOKEN_NAME, USD } from "../utils/constants";
const ProfileOwner = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const profileData = useSelector((state) => state.constants.profileData);

	const [copy, setCopy] = useState(false);
	const [search, setSearch] = useState("");
	const [list, setList] = useState([]);

	const { data, refetch } = useGetProfileQuery(profileData?.data?._id);

	const {
		data: nftList,
		refetch: nftRefetch,
		isFetching,
	} = useUserNftsQuery(profileData?.data?._id);

	useEffect(() => {
		refetch();
		nftRefetch();
	}, []);

	useEffect(() => {
		setList(nftList?.data);
	}, [nftList]);

	useEffect(() => {
		const updatedList = nftList?.data?.filter((obj) =>
			obj?.["nft-data"]?.nft_name.toLowerCase().includes(search)
		);
		setList(updatedList);
	}, [search]);

	const handleLogout = () => {
		dispatch(setLoginState(0));
		// disconnect();
		navigate("/");
	};

	return (
		<>
			<Navbar />
			<div className="bg-white mt-28">
				<section className="container">


					<div className="mt-20 flex items-center">
						<img
							src={
								data?.data?.display_picture
									? `${data?.data?.display_picture}`
									: IMG
							}
							alt=""
							className="w-80 h-80 "
						/>
						<div className="ml-10 text-left">
							{profileData?.data?.is_verified === 1 && (
								<span className="text-white bg-second px-3 py-1">
									<i class="fa-solid fa-check mr-3"></i>Verified
								</span>
							)}
							<h3 className="text-6xl font-bold bannerText mt-3">
								{data?.data?.name || "Unnamed"}
							</h3>
							<p className="text-2xl text-gray-400 mt-3">
								{profileData?.data?.wallet_address?.slice(0, 5) +
									"..." +
									profileData?.data?.wallet_address?.slice(-5)}
								<CopyToClipboard
									text={profileData?.data?.wallet_address}
									onCopy={() => setCopy(true)}
								>
									<span>
										{copy ? (
											<i class="fa-solid fa-circle-check ml-6 text-3xl text-green-600"></i>
										) : (
											<i class="fa-regular fa-copy ml-6 text-3xl text-purple-600"></i>
										)}
									</span>
								</CopyToClipboard>
								{data?.data?.link && (
									<a href={data?.data?.link} target="_blank">
										<i class="fa-solid fa-arrow-up-right-from-square ml-4 text-2xl text-pink-700"></i>
									</a>
								)}
							</p>
							<p className="text-xl text-gray-600 mt-6 font-bold">
								Joined{" "}
								{moment(profileData?.data?.createdAt).format("DD MMM YYYY")}
							</p>
							<div className="mt-4">
								<button
									className="profile-btn"
									onClick={() => navigate(`/profile/${profileData?.data?._id}`)}
								>
									Edit Profile
								</button>
								{profileData?.data?.is_verified === 0 && (
									<div className="flex items center justify-center w-full">
										<div
											className="mt-6 bg-pink-100 px-4 py-1 rounded flex items-center gap-12"
											style={{ width: "max-content" }}
										>
											<p className="text-xl">
												Please verify you email in order to purchase nfts
											</p>
											<button
												className="bg-first hover:bg-second px-3 py-1 rounded text-white text-xl text-bold"
												onClick={() => navigate(`/profile/${profileData?.data?._id}`)}
											>
												Verify
											</button>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</section>
				<section>
					<div className="flex items-center justify-between">
						<div className="flex ">
							<h2 className=" rounded-lg text-4xl font-bold cursor-pointer bannerText">
								My NFT Collection
							</h2>
						</div>
						<input
							type="text"
							placeholder="Search NFTs.."
							className="searchBorder px-1 py-3 text-2xl"
							style={{
								width: "32%",
							}}
							value={search}
							onChange={(e) => setSearch(e.target.value)}
						/>
					</div>
					{list?.length === 0 && (
						<div
							className="flex items-center justify-center mt-12 flex-col p-32"
							style={{ border: "1px solid #e3e3e3" }}
						>
							<img src={NA} alt="" className="w-48 mb-6" />
							<h2 className="text-3xl">No NFT purchased yet</h2>
						</div>
					)}
					{isFetching && <Loader />}
					<div className="nft-collection">
						{list?.length > 0 &&
							list?.map((item) => {
								return (
									<div >
										<Link to={`/product/${item?.["nft-data"]?._id}`}>
											<img src={`${item?.["nft-data"]?.nft_media[0]}`} className="h-[300px] w-full imgs" alt="" />
										</Link>

										<div className="py-6">
											<h2 className="text-3xl font-bold mb-2 bannerText txt cusror-pointer">
												{item?.["nft-data"]?.nft_name}
											</h2>

											<div className="flex justify-between mt-8">
												<div>
													<h2 className="text-xl font-bold ">Amount Paid</h2>
													<p className="text-xl">{item?.amount} {TOKEN_NAME}</p>
													<span className="text-lg text-gray-400">
														({(USD * item?.amount).toFixed(3)} USD)
													</span>
												</div>

												<div>
													<h2 className="text-xl font-bold ">
														Items Purchased
													</h2>
													<p className="text-xl">{item?.fraction_amount}</p>
												</div>
											</div>
											<div className="w-full mt-6 flex items-center justify-between">
												<h2 className="text-xl font-bold">NFT ID</h2>
												<Link to={`/product/${item?.["nft-data"]?._id}`}>
													{" "}
													<p className="text-xl txt">
														{item?.["nft-data"]?.token_id}{" "}
														<span>
															<i class="fa-solid fa-arrow-up-right-from-square ml-6"></i>
														</span>
													</p>
												</Link>
											</div>
											<div className="flex flex-col items-center gap-6 w-full mt-10">

												<button
													className="profile-btn2 w-full"

													onClick={() =>
														navigate(`/product/${item?.["nft-data"]?._id}`)
													}
												>
													View on Explorer
												</button>

												<button
													className="profile-btn w-full"


													onClick={() =>
														navigate(`/product/${item?.["nft-data"]?._id}`)
													}
												>
													Buy More
												</button>

												<button
													className="profile-btn3 w-full"


													disabled
												>
													Reedem (Coming soon)
												</button>
											</div>
										</div>
									</div>
								);
							})}
					</div>
				</section>
			</div>
			<Footer />
		</>
	);
};

export default ProfileOwner;
