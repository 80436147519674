import React from "react";
import { useNavigate } from "react-router-dom";
import ChainCheck from "./ChainCheck";
import imgs from "../assets/image/ni.png"

const CollectionCard = ({ item, url, sale, artist }) => {
	const navigate = useNavigate();

	return (
		<>
			<div className=" cursor-pointer ">
				<div onClick={() => navigate(url)}>
					<div className="relative">
						<img
							// src={`${
							// 	item?.nft_data[0]?.nft_media && item?.nft_data[0]?.nft_media[0]
							// }`}
							src={imgs}
							alt=""
							style={{ height: "250px", width: "100%" }}
						/>
						<ChainCheck itemValue={item?.nft_data[0].blockchain} />
					</div>
					<div className="py-9 px-2" style={{ borderRadius: "0 0 10px 10px" }}>
						<h2 className="text-3xl font-bold bannerText txt">
							{/* {item?.nft_data[0]?.nft_name} */}
							By {artist}
						</h2>
					</div>
				</div>
			</div>
		</>
	);
};

export default CollectionCard;
