import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ProductCard from "../components/ProductCard";
import { useMarketplaceQuery } from "../services/apis";
import NoData from "../components/NoData";
import moment from "moment";
import Loader from "../components/Loader";
import BlockSwitch from "../components/BlockSwitch";
import { useSelector } from "react-redux";
import HomeHeading from "../components/HomeHeading";

const Shop = () => {
	const { data, refetch, isFetching } = useMarketplaceQuery();

	const chainType = useSelector((state) => state?.constants?.chainType);

	const [searchQuery, setSearchQuery] = useState("");
	const [filteredItems, setFilteredItems] = useState([]);
	const [noItemsFound, setNoItemsFound] = useState(false);
	const [filter, setFilter] = useState(false);

	useEffect(() => {
		setFilteredItems(data?.data);
	}, [data]);

	useEffect(() => {
		refetch();
	}, []);

	const handleSearchChange = (e) => {
		const value = e.target.value;
		setSearchQuery(value);
		filterItems(value);
	};

	const sortNewest = (value) => {
		const sortedArray = [...filteredItems].sort((a, b) => {
			const dateA = moment(a.createdAt);
			const dateB = moment(b.createdAt);
			return value === 1 ? dateB.diff(dateA) : dateA.diff(dateB); // Sort in descending order (newest first)
		});

		setFilteredItems(sortedArray);
	};

	const priseSort = (value) => {
		const sortedArray = [...filteredItems].sort((a, b) => {
			const priceA = a?.token_owner?.price || 0; // Handle cases where price is undefined or null
			const priceB = b?.token_owner?.price || 0;

			if (value === 1) {
				return priceA - priceB;
			} else {
				return priceB - priceA;
			}
		});

		setFilteredItems(sortedArray);
	};

	const filterItems = (query) => {
		const filtered = data?.data.filter((item) =>
			item.nft_name.toLowerCase().includes(query.toLowerCase())
		);
		setFilteredItems(filtered);
		setNoItemsFound(filtered.length === 0);
	};

	useEffect(() => {
		filterItemsWithChain();
	}, [chainType]);


	const filterItemsWithChain = (query) => {
		const filtered = data?.data.filter((item) =>
			item.blockchain.includes(chainType?.name)
		);
		setFilteredItems(filtered);
	};

	return (
		<>
			<Navbar />
			<div className="pt-40 site-container">
				<div >
					<section className="gallery" id="gallery">
						<div className="mb-16 mt-12 text-center">
							<h2 className="text-6xl  font-bold text-first bannerText ">
								Marketplace
							</h2>
							<p className="text-2xl mt-6 leading-10 text-gray-700">
								Browse the full Artequitys marketplace to be exposed the finest
								collection of high quality digital NFT Artist content available
								in the world. Use the filter bar to refine your search and find
								your perfect digital art.
							</p>
						</div>
						<div className="grid grid-cols-1 md:grid-cols-3 items-center gap-8 w-full">
							<div className="flex items-center gap-6">
								<div className="flex items-center w-1/2">
									<div
										className="text-2xl mb-2 bg-white flex items-center text-black px-12 py-4 mainBorder relative cursor-pointer w-full"
										onClick={() => setFilter(!filter)}
									>
										<span>
											{" "}
											<i class="fa-solid fa-sliders mr-4 text-first"></i>Sort
										</span>
										{filter && (
											<div
												className="bg-white shadow-xl rounded-xl"
												style={{
													position: "absolute",
													left: "2px",
													top: "50px",
													width: "max-content",
													zIndex: "99",
												}}
											>
												<p
													className="hover:bg-gray-100 p-6"
													onClick={() => sortNewest(1)}
												>
													Newest
												</p>
												<p
													className="hover:bg-gray-100 p-6"
													onClick={() => sortNewest(2)}
												>
													Oldest
												</p>
												<p
													className="hover:bg-gray-100 p-6"
													onClick={() => priseSort(1)}
												>
													Price Low to High
												</p>
												<p
													className="hover:bg-gray-100 p-6"
													onClick={() => priseSort(2)}
												>
													Price High to Low
												</p>
											</div>
										)}
									</div>
								</div>
								<BlockSwitch market />
							</div>

							<div className="text-2xl mb-2 bg-white text-black px-2 py-4 searchBorder flex items-center col-span-2">
								<i class="fa-solid fa-magnifying-glass pr-2 text-first"></i>
								<input
									type="text"
									placeholder="Search NFT by Name..."
									className="w-full ml-6"
									value={searchQuery}
									onChange={handleSearchChange}
								/>
							</div>
						</div>
						<div className="container">
							{isFetching ? (
								<Loader />
							) : (
								<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
									{filteredItems?.length > 0 &&
										filteredItems?.map((item) => {
											return <ProductCard key={item?._id} data={item} />;
										})}
								</div>
							)}
						</div>
					</section>
					{filteredItems?.length === 0 && <NoData market />}
				</div>
			</div>
			{/* </section> */}
			<Footer />
		</>
	);
};

export default Shop;
