import React, { useEffect, useRef } from "react";
import styles from "./Modal.module.css";

const Modal = ({
	modalStyle,
	children,
	show,
	onClose,
	backdropStyle,
	title = "",
	isScale = false,
}) => {
	const modalRef = useRef(null);
	useEffect(() => {
		if (show) {
			modalRef.current.classList.add(styles.visible);
		} else {
			modalRef.current.classList.remove(styles.visible);
		}
	}, [show]);

	return (
		<React.Fragment>
			<div
				ref={modalRef}
				style={backdropStyle}
				className={`${styles.modal__wrap}`}
				onClick={onClose}
			// onClick ={handleClick}
			>
				<div
					style={modalStyle}
					className={`${isScale ? styles.modal2 : styles.modal} flex flex-col p-6`}
					onClick={(e) => e.stopPropagation()}
				>
					<div className="flex items-start justify-between gap-6 mb-4 pb-9" >
						<h3 className="text-3xl bannerText">{title}</h3>

						<button
							onClick={onClose}
							style={{ border: "none", zIndex: 1 }}
						
						>
							<i
								class="fa-solid fa-xmark bold"
								style={{
									color: isScale ? "white" : "#444",
									fontSize: isScale ? "32px" : "20px",
								}}
							></i>
						</button>
					</div>
					{children}
				</div>
			</div>
		</React.Fragment>
	);
};

export default Modal;
