import React from "react";
import { Link } from "react-router-dom";
import { TOKEN_NAME, USD } from "../utils/constants";
import ChainCheck from "./ChainCheck";
const ProductCard = ({ data, home }) => {
  return (
    <div className=" bg-white">
      <div className="sold-nft ">
        {data?.token_owner?.on_sale_fractions -
          (data?.token_owner?.fraction_amount -
            data?.token_owner?.remaining_fraction) ===
          0 && (
          <div className="sold-box ">
            <p className="bannerText">SOLD</p>
          </div>
        )}
        <Link to={`/product/${data?._id}`} state={{ item: data }}>
          <div className="relative">
            <img
              src={`${data?.nft_media[0]}`}
              style={{
                opacity:
                  data?.token_owner?.on_sale_fractions -
                    (data?.token_owner?.fraction_amount -
                      data?.token_owner?.remaining_fraction) ===
                  0
                    ? "0.2"
                    : "1",
                height: "270px",
                width: "100%",
              }}
              className="imgs"
              alt
            />

            <ChainCheck itemValue={data?.blockchain} />
          </div>
          <div
            style={{ textAline: "left" }}
            className="bg-white mt-0 text-left py-5"
          >
            <h3 className="text-black text-3xl txt bannerText font-bold mb-2">
              {data?.nft_name?.length > 30
                ? data?.nft_name?.slice(0, 30) + "..."
                : data?.nft_name}
            </h3>
            <p className="text-xl text-gray-400">
              Created By{" "}
              <span className="font-bold text-first">
                {data?.user_data?.name}
              </span>
            </p>
            <div className="flex justify-between bg-gray-50 p-6 mt-6">
              <div>
                <h3 className="text-gray-400 text-2xl font-bold">Fractions </h3>
                <p className="text-black text-2xl font-bold">
                  #{data?.token_owner?.amount}
                </p>
              </div>
              <div>
                {/* <h3 className="text-gray-400 text-2xl font-bold mt-4">
									PRICE{" "}
								</h3> */}
                <p className="text-black text-2xl font-bold">
                  ${data?.token_owner?.price}
                  <br />
                  <span className="text-lg text-gray-400">
                    ({(USD * data?.token_owner?.price).toFixed(3)} ArtE)
                  </span>
                </p>
              </div>
            </div>
          </div>
        </Link>
      </div>
      {/* <button
				class="bg-gray-200 w-full p-4 text-2xl uppercase font-bold rounded-xl text-gray-700"
				onClick={() => navigate(`/product/${data?._id}`)}
			>
				{profileData?.data?.role === "user" ? "Buy Now" : "View Detail"}
			</button> */}
    </div>
  );
};

export default ProductCard;
