import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Loader from "../components/Loader";
import { useGetTermQuery } from "../services/apis";
import parse from "html-react-parser";

const TermPage = () => {
	const { data, refetch, isFetching } = useGetTermQuery();

	useEffect(() => {
		refetch();
	}, []);

	return (
		<>
			<Navbar />
			{isFetching ? (
				<Loader />
			) : (
				<>
					<div className="bg-white mt-28 mb-0 ">
						<section
							className="site-container cmsPage pt-20 pb-20"
							style={{ marginBottom: "0px" }}
						>
							{parse(`${data?.data?.terms_and_condition}`)}
						</section>
					</div>
				</>
			)}
			<Footer />
		</>
	);
};

export default TermPage;
