import React from "react";
import imgs from "../assets/image/ni.png"

import { useMarketplaceQuery } from "../services/apis";
import { useEffect } from "react";

import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";
import { Link } from "react-router-dom";

import "@splidejs/react-splide/css";
// or other themes
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";
// or only core styles
import "@splidejs/react-splide/css/core";
import HomeHeading from "./HomeHeading";
import { TOKEN_NAME } from "../utils/constants";

const Trending = () => {
	const { data, refetch } = useMarketplaceQuery();

	useEffect(() => {
		refetch();
	}, []);
	return (
		<div className="p-10 bg-gray-50">
			<section className="review mb-28 " id="review" style={{ padding: "3em" }}>
				<HomeHeading
					title="Popular Arts"
					description=""
				/>
				<div className="swiper review-slider ">
					<div className="swiper-wrapper  site-container ">
						<Splide
							hasTrack={false}
							options={{
								rewind: true,
								type: "slide",
								gap: "2rem",
								perPage: 4,
								arrows: true,
								pagination: false,
								breakpoints: {
									580: {
										perPage: 1,
									},
									720: {
										perPage: 2,
									},
									1080: {
										perPage: 3,
									},
								},
							}}
						>
							<SplideTrack>
								{data?.data?.length > 0 &&
									data?.data?.map((item, index) => {
										return (
											<SplideSlide>
												<div className=" ">
													<div className="swiper-slide box mt-20">
														<Link
															to={`/product/${item?._id}`}
															state={{ item: data }}
														>
															<img
																// src={`${item?.nft_media[0]}`}

																src={imgs}
																className=" imgs"
																style={{ height: "300px" }}
																alt
															/>
														</Link>
														<div className="py-1">

															<h3
																className="bannerText mt-2 font-bold txt cursor-pointer"
																style={{ color: "#000", fontSize: "19px" }}
															>
																{item?.nft_name?.length > 30
																	? item?.nft_name?.slice(0, 30)
																	: item?.nft_name}
															</h3>
															<div className="text-black text-2xl mt-0"><span>$ {item?.token_owner?.price}</span></div>
														</div>
													</div>
												</div>
											</SplideSlide>
										);
									})}
							</SplideTrack>
						</Splide>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Trending;
